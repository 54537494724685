import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Row,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import {
  DROPZONE_MAX_VIDEO_MB_SIZE,
  DROPZONE_VIDEO_FORMATS,
  INITIAL_VIDEO_FILE_UPLOAD_COUNT,
  INITIAL_VIDEO_FILE_PROJECT_DATA,
  PROJECT_NAME_REGEX,
} from "../../../constants/constants";
import Dropzone from "react-dropzone";
import { FileUploadCount, UploadVideoFileData, VideoFileProjectData } from "../../../constants/types";
import { capitalize, formatBytes } from "../../../utils/utils";
import { InferenceState } from "../../../constants/enums/inference_enums";

interface CreateVideoProjectModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  title: string;
  videoProjectData: VideoFileProjectData;
  setVideoProject: React.Dispatch<React.SetStateAction<VideoFileProjectData>>;
  fileUploadCount: FileUploadCount;
  setFileUploadCount: React.Dispatch<React.SetStateAction<FileUploadCount>>;
  handleUpload: (videoProjectData: VideoFileProjectData) => void;
}

const initialVideoProjectError: VideoFileProjectData = {
  project: "",
  uploadFileData: "",
};

const CreateVideoProjectModal = ({
  isOpen,
  toggleModal,
  title,
  videoProjectData,
  setVideoProject,
  fileUploadCount,
  setFileUploadCount,
  handleUpload,
}: CreateVideoProjectModalProps) => {
  const maxFileSize = DROPZONE_MAX_VIDEO_MB_SIZE * 1024 * 1024; // MB in bytes
  const [errors, setErrors] = useState<VideoFileProjectData>(initialVideoProjectError);

  useEffect(() => {
    setVideoProject(INITIAL_VIDEO_FILE_PROJECT_DATA);
    setFileUploadCount(INITIAL_VIDEO_FILE_UPLOAD_COUNT);
    setErrors(initialVideoProjectError);
  }, [isOpen]);

  const handleAcceptedFiles = (acceptedFiles: File[]) => {
    const allSelectedFiles: UploadVideoFileData[] = acceptedFiles.map((file: File, fileIndex: number) => {
      const uploadFileData: UploadVideoFileData = {
        file: file,
        fileNumber: fileIndex + 1,
        status: InferenceState.SELECTED,
        uploadPercentage: 0,
      };
      return uploadFileData;
    });

    setFileUploadCount((prevState) => ({
      ...prevState,
      totalCount: acceptedFiles ? acceptedFiles.length : 0,
      status: InferenceState.SELECTED,
    }));

    setVideoProject((prevState) => ({
      ...prevState,
      uploadFileData: allSelectedFiles ? allSelectedFiles : [],
    }));
  };

  const handleFileUpload = async () => {
    // if (!videoProjectData.project || videoProjectData.uploadFileData.length === 0) return;
    if (videoProjectData.uploadFileData.length === 0) return;
    if (validateForm()) {
      handleUpload(videoProjectData);
    }
  };

  const toggleAndClear = () => {
    toggleModal();
    setVideoProject(INITIAL_VIDEO_FILE_PROJECT_DATA);
  };

  const handleProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const projectName = e.target.value;
    setVideoProject((prevState: VideoFileProjectData) => ({
      ...prevState,
      project: projectName,
    }));
  };

  const validateForm = (): boolean => {
    let isValid = true;
    const formErrors: VideoFileProjectData = { ...initialVideoProjectError };
    if (videoProjectData.project.length < 3) {
        formErrors.project = 'Project name is short';
        isValid = false;
    }
    
    if (!PROJECT_NAME_REGEX.test(videoProjectData.project)) {
        formErrors.project = 'Project name can only contain letters, and numbers';
        isValid = false;
    }

    if (!videoProjectData.uploadFileData || videoProjectData.uploadFileData.length === 0) {
      formErrors.uploadFileData = "At least one file is required";
      isValid = false;
    }

    setErrors(formErrors);

    return isValid;
  };

  const hasAnyUploadedOrUploading = videoProjectData.uploadFileData.some(
    (fileData: UploadVideoFileData) =>
      fileData.status === InferenceState.UPLOADED || fileData.status === InferenceState.UPLOADING
  );
  const hasAnyUploading = videoProjectData.uploadFileData.some(
    (fileData: UploadVideoFileData) => fileData.status === InferenceState.UPLOADING
  );
  const hasEveryUploaded = videoProjectData.uploadFileData.every(
    (fileData: UploadVideoFileData) => fileData.status === InferenceState.UPLOADED
  );

  // const isSubmitDisabled = !videoProjectData.project || hasAnyUploadedOrUploading || hasEveryUploaded || videoProjectData.uploadFileData.length === 0 || videoProjectData.uploadFileData.reduce((totalSize: number, fileData: UploadFileData) => totalSize + fileData.file.size, 0) > maxFileSize;
  const isSubmitDisabled =
    hasAnyUploadedOrUploading ||
    hasEveryUploaded ||
    videoProjectData.uploadFileData.length === 0 ||
    videoProjectData.uploadFileData.reduce(
      (totalSize: number, fileData: UploadVideoFileData) => totalSize + fileData.file.size,
      0
    ) > maxFileSize;

  videoProjectData.uploadFileData;

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={toggleAndClear} backdrop={"static"}>
        <ModalHeader toggle={toggleAndClear} tag="h5">
          {title}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <div className="mb-3">
                <Label htmlFor="project" className="form-label">
                  Project name
                </Label>
                <Input
                  required
                  id="project"
                  name="project"
                  type="text"
                  className="form-control"
                  placeholder="Enter project name"
                  onChange={handleProjectNameChange}
                />
                {errors.project && <div className="text-danger mb-3">{errors.project}</div>}
              </div>
            </Row>

            <Row>
              <Label htmlFor="projectName" className="form-label">
                Attached video files
              </Label>
              <Dropzone
                // maxFiles={DROPZONE_MAX_VIDEO_FILES}
                multiple={true}
                accept={DROPZONE_VIDEO_FORMATS}
                onDrop={(acceptedFiles) => {
                  handleAcceptedFiles(acceptedFiles);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone-modal">
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="icon-display-4 text-muted fas fa-cloud-upload-alt"></i>
                      </div>

                      {fileUploadCount && fileUploadCount.status.toLowerCase() === InferenceState.UPLOADING && (
                        <h5 className="font-size-16">
                          Uploading <strong>{fileUploadCount.fileNumber}</strong> of {fileUploadCount.totalCount}{" "}
                          {fileUploadCount.totalCount == 1 ? "video" : "videos"}
                        </h5>
                      )}
                      {fileUploadCount && fileUploadCount.status.toLowerCase() === InferenceState.UPLOADED && (
                        <h5 className="font-size-16">
                          {fileUploadCount.totalCount} {fileUploadCount.totalCount == 1 ? "video" : "videos"} uploads
                          complete
                        </h5>
                      )}
                      {fileUploadCount.status.toLowerCase() === InferenceState.SELECTED &&
                        videoProjectData.uploadFileData.length > 0 && (
                          <h5 className="font-size-16">
                            You have selected <strong>{videoProjectData.uploadFileData.length}</strong>{" "}
                            {videoProjectData.uploadFileData.length === 1 ? "video" : "videos"}
                          </h5>
                        )}
                      {fileUploadCount.status.toLowerCase() === InferenceState.LOADED &&
                        videoProjectData.uploadFileData.length === 0 && (
                          <h5 className="font-size-16">Drag & Drop your video file here or Click to browse</h5>
                        )}
                    </div>
                  </div>
                )}
              </Dropzone>
            </Row>

            <Row>
              <Col lg={12} md={12} xs={12}>
                <div className="dropzone-previews mt-3" id="file-previews">
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                    {videoProjectData.uploadFileData.map((fileData: UploadVideoFileData, i: number) => {
                      return (
                        <div className="p-1" key={i}>
                          <Row className="align-items-center">
                            <div className="text-dark fw-medium mb-1">
                              <i className="fas fa-play-circle align-middle text-danger me-0"> </i>
                              {fileData.file.name}
                              {" ("}
                              {fileData.file.size > maxFileSize && (
                                <strong
                                  style={{
                                    color: "red",
                                    textAlign: "center",
                                  }}
                                >
                                  {formatBytes(fileData.file.size)} (Max. file size is {DROPZONE_MAX_VIDEO_MB_SIZE} MB)
                                </strong>
                              )}
                              {fileData.file.size <= maxFileSize && <strong>{formatBytes(fileData.file.size)}</strong>}
                              {")  - "} <strong>{fileData.status && capitalize(fileData.status)}</strong>
                            </div>
                            <div className="mb-0">
                              <Progress
                                value={fileData.uploadPercentage!}
                                style={{ height: "20px" }}
                                color="info"
                                className="bg-gradient mb-1"
                                max={100}
                              >
                                <b>{fileData.uploadPercentage!}%</b>
                              </Progress>
                            </div>
                          </Row>
                        </div>
                      );
                    })}
                  </Card>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Row>
            <Col lg={12} md={12} xs={12}>
              <div className="text-end mt-2">
                <div className="d-flex justify-content-end flex-wrap gap-2 mb-3">
                  <Button color="btn btn-secondary-subtle" onClick={toggleAndClear}>
                    Cancel
                  </Button>
                  <Button color="btn btn-primary-subtle" onClick={handleFileUpload} disabled={isSubmitDisabled}>
                    Submit
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default CreateVideoProjectModal;
