import React, {ReactNode, useEffect} from "react";

//import components
import Header from "./Header";
import LeftSidebar from "./LeftSidebar";
import Footer from "./Footer";

//constants
import {
  layoutTypes
} from "../constants/enums/layout";

//import actions
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopBarTheme,
  changeLayoutWidth,
  changeLayoutMode,
} from "../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

interface LayoutProps {
  children: ReactNode;
}
const Layout = (layoutProps: LayoutProps) => {
  const dispatch = useDispatch();

  const selectLayoutProperties = createSelector(
    (state: any) => state.Layout,
    (layout) => ({
      layoutMode: layout.layoutMode,
      leftSideBarType: layout.leftSideBarType,
      layoutWidth: layout.layoutWidth,
      topBarTheme: layout.topBarTheme,
      layoutType: layout.layoutType,
      leftSideBarTheme: layout.leftSideBarTheme,
    })
  );
  // Inside your component
  const {
    layoutMode,
    layoutWidth,
    leftSideBarType,
    topBarTheme,
    layoutType,
    leftSideBarTheme,
  } = useSelector(selectLayoutProperties);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(changeLayout(layoutTypes.VERTICAL));
  }, [dispatch]);

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }
  }, [leftSideBarTheme, dispatch]);

  useEffect(() => {
    if (layoutMode) {
      dispatch(changeLayoutMode(layoutMode, layoutType));
    }
  }, [layoutMode, dispatch]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [layoutWidth, dispatch]);

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
  }, [leftSideBarType, dispatch]);

  useEffect(() => {
    if (topBarTheme) {
      dispatch(changeTopBarTheme(topBarTheme));
    }
  }, [topBarTheme, dispatch]);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <LeftSidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <div className="main-content">
          {layoutProps.children}
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;