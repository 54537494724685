import {getFirestore, Firestore, serverTimestamp, QuerySnapshot, query, where, orderBy, startAfter, limit, getDoc, addDoc, updateDoc, deleteDoc, getDocs, doc, collection} from 'firebase/firestore';
import {FirebaseApp} from "@firebase/app";
import {authErrorMessages} from "../config/auth_config";
import {FirebaseAuthErrorCode} from "../constants/enums/inference_enums";
import {CollectionReference, DocumentReference, Query} from "@firebase/firestore";

export class FirestoreService {
    private store: Firestore;
    constructor(app: FirebaseApp) {
        this.store = getFirestore(app);
        // this.fetchData("users");
        // this.addNewUserToFirestore();
    }

    createDocument = async (yourCollection: string, data: any) => {
        try {
            const collectionRef: CollectionReference = collection(this.store, yourCollection);
            const documentRef: DocumentReference = await addDoc(collectionRef, data);
            console.log('Post created with ID: ', documentRef.id)
            return documentRef;
        } catch (error) {
            console.error('Error creating post: ', error);
            throw this._handleError(error);
        }
    };

    getDocument = async (yourCollection: string, documentId: string) => {
        try {
            const documentRef: DocumentReference = doc(this.store, yourCollection, documentId);
            const docSnapshot = await getDoc(documentRef);

            if (docSnapshot.exists()) {
                console.log('Document data:', docSnapshot.data());
            } else {
                console.log('No such document!');
            }
        } catch (error) {
            console.error('Error getting document:', error);
        }
    };

    getDocuments = async (yourCollection: string) => {
        try {
            const collectionRef:CollectionReference = collection(this.store, yourCollection);
            const querySnapshot:QuerySnapshot = await getDocs(collectionRef);

            querySnapshot.forEach((doc) => {
                console.log(doc.id, ' => ', doc.data());
            });

            return querySnapshot;
        } catch (error) {
            console.error('Error fetching posts: ', error);
            throw this._handleError(error);
        }
    };

    updateDocument = async (yourCollection: string, documentId: string, updateData: any) => {
        try {
            const postRef: DocumentReference = doc(this.store, yourCollection, documentId);
            await updateDoc(postRef, updateData);
            console.log('Post updated successfully');
        } catch (error) {
            console.error('Error updating post: ', error);
            // throw this._handleError(error);
        }
    };

    deletePost = async (yourCollection: string, documentId: string) => {
        try {
            const collectionRef: DocumentReference = doc(this.store, yourCollection, documentId);
            await deleteDoc(collectionRef);
            console.log('Post deleted successfully');
        } catch (error) {
            console.error('Error deleting post: ', error);
            // throw this._handleError(error);
        }
    };

    searchData = async (yourCollection: string, searchTerm: string, sortBy: string, sortOrder: 'asc' | 'desc', pageNumber: number, pageSize: number) => {
        try {
            const postsCollectionRef: CollectionReference = collection(this.store, yourCollection);
            let q = query(postsCollectionRef);

            // Add search/filter conditions
            if (searchTerm) {
                q = query(q, where('title', '>=', searchTerm));
            }

            // Add sorting
            if (sortBy && sortOrder) {
                q = query(q, orderBy(sortBy, sortOrder));
            }

            // Pagination
            if (pageNumber && pageSize) {
                const startAtDoc = (pageNumber - 1) * pageSize;
                q = query(q, startAfter(startAtDoc), limit(pageSize));
            }

            // Execute the query
            const querySnapshot = await getDocs(q);

            // Process the query results
            const records: any[] = [];
            querySnapshot.forEach((doc) => {
                records.push({ id: doc.id, ...doc.data() });
            });

            // Calculate total pages
            const totalRecords = querySnapshot.size;
            const totalPages = Math.ceil(totalRecords / pageSize);

            return { records, totalPages };
        } catch (error) {
            console.error('Error fetching posts:', error);
            return { posts: [], totalPages: 0 };
        }
    };

    // Fetch the documents from the collection
    private fetchData = async (yourCollection: string) => {
        // Define a reference to a document
        const documentRef:DocumentReference = doc(this.store, yourCollection, 'your_document_id');
        // Define a reference to a sub-collection
        const collectionRef: CollectionReference = collection(this.store, yourCollection);

        try {
            const querySnapshot = await getDocs(collectionRef);
            querySnapshot.forEach((doc) => {
                console.log(doc.id, ' => ', doc.data());
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    private addNewUserToFirestore = async () => {
        // const {profile} = user.additionalUserInfo;
        const details = {
            firstName: "Tikaraj",
            lastName: "Ghising",
            fullName: "Tikaraj Ghising",
            email: "tikarajgg@gmail.com",
            // picture: profile.picture,
            createdDtm: serverTimestamp(),
            lastLoginTime: serverTimestamp()
        };

        try {
            // const userDocRef = doc(this.test, 'users', user.uid);
            // await setDoc(userDocRef, details);
            await addDoc(collection(this.store, 'users'), details);
            return {details};
            // return {user, details};
        } catch (error) {
            throw this._handleError(error);
        }
    };

    private _handleError(error: any) {
        if (error && error.code && error.message) {
            const errorMessage = authErrorMessages[error.code as FirebaseAuthErrorCode];
            return {code: error.code, message: errorMessage};
        } else {
            return {code: 'unknown', message: 'An unknown error occurred'};
        }
    }

}
