import { registerUserSuccessful, registerUserFailed } from "./reducer";

//Include Both Helper File with needed methods
import {getFirebaseAuthService} from "../../../utils/firebase_utils";

export const registerUser:any = (user: any) => async (dispatch: any) => {
    try {
        let response;
        const firebaseAuthService = getFirebaseAuthService();
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            response = firebaseAuthService!.registerUser(user.email, user.password);
        }
        const data = await response;
        if (data) {
            dispatch(registerUserSuccessful(data));
        }

    } catch (error) {
        dispatch(registerUserFailed(error))
    }
};