import axios, {AxiosInstance} from "axios";
import {
    SERVER_BASE_URL_CONFIG,
    API_KEY_ENDPOINT, API_KEY_VALID_ENDPOINT
} from "../utils/url_helper";
import {headerContentType} from "../utils/auth-token-header";

class AuthAPIKeyService {
    private serverAPI: AxiosInstance;

    constructor() {
        this.serverAPI = axios.create({
            baseURL: SERVER_BASE_URL_CONFIG().apiBaseUrl,
            headers: {
                'Content-Type': headerContentType.JSON_TYPE,
                'Authorization': `${process.env.REACT_APP_SCANNER_TECH_TOKEN}`
            }
        });
    }
    generateApiKey = (data: any) => {
        // const responseData = {api_key: "generated_api_key", message:"Please save this secret key somewhere safe and accessible."} // 201 , 200
        return this.serverAPI.post(`/${API_KEY_ENDPOINT}`, data, {
            headers: {
                'Content-Type': headerContentType.JSON_TYPE,
                'Authorization': `${process.env.REACT_APP_SCANNER_TECH_TOKEN}`
            }
        });
    }

    getApiKey = () => {
        // const response = {
        //     encrypted_key: "string",
        //     hashed_key: "string", owner: "scanner@deakin.edu.au"
        // }
        return this.serverAPI.post(`/${API_KEY_ENDPOINT}`, {
            headers: {
                'Content-Type': headerContentType.JSON_TYPE,
                'Authorization': `${process.env.REACT_APP_SCANNER_TECH_TOKEN}`
            }
        });
    }
    deleteApiKey = (data: any) => {
        // const requestBody = { api_key:"your_api_key" }
        // return {"message": "Invalid API Key"}, 400 if not valid api_key
        // return {"message": "API key removed."}, 200
        return this.serverAPI.delete(`/${API_KEY_ENDPOINT}`, {
            headers: {
                'Content-Type': headerContentType.JSON_TYPE,
                'Authorization': `${process.env.REACT_APP_SCANNER_TECH_TOKEN}`
            },
            data
        });
    }

    validateApiKey = (data: any) => {
        // const requestData = { api_key: userAction.authUser.accessXApiKey};
        return this.serverAPI.post(`/${API_KEY_VALID_ENDPOINT}`, data, {
            headers: {
                'Content-Type': headerContentType.JSON_TYPE,
                'Authorization': `${process.env.REACT_APP_SCANNER_TECH_TOKEN}`
            }
        });
    }
}

export default new AuthAPIKeyService();