import React from "react";
import {
    addToolbarButton,
    createIcon,
    IconButton, isImageSlide, useLightboxProps,
    useLightboxState
} from "yet-another-react-lightbox";
import {downloadInferenceImage} from "../../utils/image_inference_utils";
const PLUGIN_CUSTOM_DOWNLOAD = "custom-download";


// Create a custom download icon for plugin
const CustomDownloadIcon = createIcon("CustomDownloadIcon", React.createElement("path", { d: "M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zm-1-4-1.41-1.41L13 12.17V4h-2v8.17L8.41 9.59 7 11l5 5 5-5z" }));

// Define your custom plugin component
function CustomDownloadButton() {
    const { currentSlide, currentIndex } = useLightboxState();
    const { render, on, download: downloadProps } = useLightboxProps();

    const canDownload = currentSlide !== null && currentSlide.download && isImageSlide(currentSlide)

    const customDownloadImage = async () => {
        if (canDownload || (currentSlide !== null && currentSlide.download && currentSlide.src)) {
            const imageUrl = currentSlide.src;
            const fileName = currentSlide.title ? currentSlide.title : currentSlide.description;

            await downloadInferenceImage(imageUrl, fileName);
        }
    };
    return (
        <IconButton label="Download" icon={CustomDownloadIcon} renderIcon={render.iconDownload} disabled={!canDownload} onClick={customDownloadImage} />
    );
}

export function CustomDownload({ augment }) {
    augment(({ toolbar, ...restProps }) => ({
        toolbar: addToolbarButton(toolbar, PLUGIN_CUSTOM_DOWNLOAD, <CustomDownloadButton />),
        ...restProps,
    }));
}

