import {
  BoundingBoxInstance,
  CSVDataProps,
  CSVHeader,
  csvHeaders,
  ProcessedVideoStateData,
  ScannerParameters,
  VideoObjectDetectionJsonData,
} from "../constants/types";
import { AuthUserData } from "../constants/models/Models";
import FetchService from "../services/FetchService";
import { notifyError, notifySuccess } from "../services/NotificationService";
import { capitalize, formattedCurrentDate, getFileNameFromUrl, triggerFileDownloadProcess } from "./utils";
import { InferenceState, ResponseType } from "../constants/enums/inference_enums";
import JSZip from "jszip";
import { CHUNK_FILE_SIZE_MB } from "../constants/constants";

const convertToCSV = (data: any[], headers: CSVHeader[], separator: string = ","): string => {
  if (!data || data.length === 0) {
    return "";
  }
  // Create the header row
  const headerRow = headers.map((header) => header.label);

  // Create the columns codes
  const columns = headers.map((header) => header.code);
  const csv = [
    headerRow.join(separator), // header row first
    ...data.map((row) => columns.map((key) => row[key]).join(separator)),
  ].join("\r\n");

  return csv;
};

const exportCSV = async (jsonData: any[], headers: CSVHeader[], fileName: string = "video-summary") => {
  const csvData = convertToCSV(jsonData, headers, ",");
  const uFEFF = "\uFEFF"; // Byte Order Mark for UTF-8
  const blob = new Blob([uFEFF, csvData], { type: "text/csv;charset=utf-8;" });
  triggerFileDownloadProcess(blob, `${fileName}.csv`);
};

export const downloadProcessedVideoCSV = async (
  processedVideo: ProcessedVideoStateData,
  authUser: AuthUserData,
  scannerParameters: ScannerParameters = {},
  isCSVExport: boolean = false
) => {
  if (processedVideo) {
    try {
      const response = await FetchService.downloadJSON(processedVideo.urls.json);
      if (response && response.status === 200) {
        const data = response.data;
        if (data) {
          const objectDetectionData: VideoObjectDetectionJsonData = data as VideoObjectDetectionJsonData;
          const filteredCSVData = filteredVideoCSVData(objectDetectionData, authUser, scannerParameters, isCSVExport);
          // Export CSV
          exportCSV(filteredCSVData, csvHeaders, objectDetectionData?.video_details.name).then();
          notifySuccess("CSV has been exported successfully.");
        }
      } else {
        console.error("Response is not a valid data:", response);
        notifyError("Export CSV failed. Please try again.");
      }
    } catch (error) {
      console.error("Error download files:", error);
      console.error(error);
      notifyError("An unexpected error occurred during the Export CSV.");
    }
  } else {
    console.error("Invalid inference data for download csv.");
  }
};

export const filteredVideoCSVData = (
  objectDetectionData: VideoObjectDetectionJsonData,
  authUser: AuthUserData,
  scannerParameters: ScannerParameters = {},
  isCSVExport: boolean = false
) => {
  const exportBy = authUser.displayName ? authUser.displayName + "-" + authUser.email : authUser.email;
  const allCSVData: CSVDataProps[] = [];
  const adCountBrandsCSVData: { [key: string]: CSVDataProps } = {};
  const AD_FRAME_GAP = 450;

  const selectedCountry = scannerParameters?.country ? scannerParameters?.country?.value : null;
  const categories: any[] = [];
  scannerParameters.category?.forEach((eachItem) => {
    categories.push(eachItem.value);
  });

  objectDetectionData?.results?.forEach((eachFrame) => {
    const eachBoundingBoxes: BoundingBoxInstance[] | undefined = eachFrame.inference?.merged?.instances;
    eachBoundingBoxes?.forEach((eachBoundingBox) => {
      let currentAdNumber = 1;
      let adNumberBrandName = currentAdNumber + "_" + eachBoundingBox.brand;
      const newData: CSVDataProps = {
        user: exportBy,
        videoName: objectDetectionData?.video_details.name,
        frame: eachFrame.frame,
        frameGap: 0,
        startTimeCode: eachFrame.time_code,
        endTimeCode: eachFrame.time_code,
        market: capitalize(eachBoundingBox.market),
        category: isCSVExport ? `"${eachBoundingBox.category}"` : eachBoundingBox.category,
        brandName: isCSVExport ? `"${eachBoundingBox.brand}"` : eachBoundingBox.brand,
        adNumber: currentAdNumber,
        adNumberBrandName: adNumberBrandName,
        brandCount: 1,
        totalBrandCount: "",
        regions: eachBoundingBox.regions,
      };

      if (allCSVData.length > 0) {
        const lastCSVRow = allCSVData[allCSVData.length - 1];
        const currentFrameGap = eachFrame.frame - lastCSVRow.frame;
        newData.frameGap = currentFrameGap;
        if (currentFrameGap > AD_FRAME_GAP) {
          // Calculate the new Add number
          currentAdNumber = lastCSVRow.adNumber + 1;
          adNumberBrandName = currentAdNumber + "_" + eachBoundingBox.brand;
          newData.adNumber = currentAdNumber;
          newData.adNumberBrandName = adNumberBrandName;

          // Capture each Ad Number Start Time and End Time
          newData.startTimeCode = eachFrame.time_code;
          newData.endTimeCode = eachFrame.time_code;
        } else {
          currentAdNumber = lastCSVRow.adNumber;
          adNumberBrandName = currentAdNumber + "_" + eachBoundingBox.brand;
          newData.adNumber = currentAdNumber;
          newData.adNumberBrandName = adNumberBrandName;

          // Capture each Ad Number Start Time and End Time
          newData.startTimeCode = lastCSVRow.startTimeCode;
          newData.endTimeCode = eachFrame.time_code;
        }
      }
      // add new data to initial csv data
      allCSVData.push(newData);

      // Prepare data based on ad count and brands for selected country, category or else
      if (selectedCountry && categories.length > 0) {
        if (
          newData.regions &&
          newData.market &&
          newData.regions.includes(selectedCountry) &&
          categories?.includes(newData.market.toLowerCase())
        ) {
          // Ad unique Ad Count based Brand name
          if (adCountBrandsCSVData[newData.adNumberBrandName]) {
            newData.brandCount = adCountBrandsCSVData[newData.adNumberBrandName].brandCount + 1;
            adCountBrandsCSVData[newData.adNumberBrandName] = newData;
          } else {
            newData.brandCount = 1;
            adCountBrandsCSVData[newData.adNumberBrandName] = newData;
          }
        }
      } else if (selectedCountry && categories.length === 0) {
        if (newData.regions && newData.regions.includes(selectedCountry)) {
          // Ad unique Ad Count based Brand name
          if (adCountBrandsCSVData[newData.adNumberBrandName]) {
            newData.brandCount = adCountBrandsCSVData[newData.adNumberBrandName].brandCount + 1;
            adCountBrandsCSVData[newData.adNumberBrandName] = newData;
          } else {
            newData.brandCount = 1;
            adCountBrandsCSVData[newData.adNumberBrandName] = newData;
          }
        }
      } else {
        // Ad unique Ad Count based Brand name
        if (adCountBrandsCSVData[newData.adNumberBrandName]) {
          newData.brandCount = adCountBrandsCSVData[newData.adNumberBrandName].brandCount + 1;
          adCountBrandsCSVData[newData.adNumberBrandName] = newData;
        } else {
          newData.brandCount = 1;
          adCountBrandsCSVData[newData.adNumberBrandName] = newData;
        }
      }
    });
  });

  const calculatedCSVData: CSVDataProps[] = [];

  // update the total brand count for AdCount based unique brands last index
  Object.keys(adCountBrandsCSVData).forEach((adCountBrandName) => {
    const eachAdCountBrandCSVData: CSVDataProps = adCountBrandsCSVData[adCountBrandName];
    const lastCSVData = calculatedCSVData[calculatedCSVData.length - 1];
    if (lastCSVData && lastCSVData.adNumber === eachAdCountBrandCSVData.adNumber) {
      eachAdCountBrandCSVData.startTimeCode = "";
      lastCSVData.endTimeCode = "";
      calculatedCSVData[calculatedCSVData.length - 1] = lastCSVData;
    } else {
      calculatedCSVData[calculatedCSVData.length - 1] = lastCSVData;
    }
    calculatedCSVData.push(eachAdCountBrandCSVData);
  });
  return calculatedCSVData;
};

/**
 * Downloads a video file in chunks.
 * @param {string} videoUrl - The URL of the video to download.
 * @param {number} chunkFileSize - The size of each chunk (in MB).
 * @param {(progress: number) => void} onProgress - Optional callback to monitor download progress.
 * @returns {Promise<Blob>} - A promise that resolves to a Blob of the complete video file.
 */
export const downloadVideoInChunks = async (
  videoUrl: string,
  chunkFileSize: number,
  onProgress: (progress: number) => void = () => {}
): Promise<Blob> => {
  let startByte = 0;
  let fileChunks: Blob[] = [];
  let totalSize = 0;
  let fileType = "video/mp4";

  const chunkSize = chunkFileSize * 1024 * 1024; // MB in bytes

  try {
    // Get file size by making a HEAD request
    const fileSize = await getFileSize(videoUrl);

    // Download video in chunks
    while (startByte < fileSize) {
      // EndByte
      const endByte = Math.min(startByte + chunkSize - 1, fileSize - 1);
      const chunk: Blob = await downloadChunk(videoUrl, startByte, endByte);

      // Append the downloaded chunk
      fileChunks.push(chunk);
      totalSize += chunk.size;

      // Update progress
      const progressPercentage = (totalSize / fileSize) * 100;
      onProgress(progressPercentage); // Invoke progress callback
      // console.log(`Downloaded ${progressPercentage.toFixed(2)}%`);

      startByte += chunkSize;
      fileType = chunk.type;
    }

    // Combine chunks and return the final Blob
    return new Blob(fileChunks, { type: fileType });
  } catch (error) {
    console.error("Error while downloading video:", error);
    throw error;
  }
};

/**
 * Helper function to get the file size from a video URL.
 * @param {string} videoUrl - The video URL.
 * @returns {Promise<number>} - The file size in bytes.
 */
const getFileSize = async (videoUrl: string): Promise<number> => {
  const response = await FetchService.getDownloadVideoSize(videoUrl);
  const fileSize = parseInt(response.headers["content-length"], 10);
  if (!fileSize) {
    throw new Error("Unable to retrieve file size.");
  }
  return fileSize;
};

/**
 * Downloads a specific chunk of the video.
 * @param {string} videoUrl - The video URL.
 * @param {number} startByte - The starting byte for the chunk.
 * @param {number} endByte - The ending byte for the chunk.
 * @returns {Promise<Blob>} - The downloaded chunk in Blob format.
 */
const downloadChunk = async (videoUrl: string, startByte: number, endByte: number): Promise<Blob> => {
  const response = await FetchService.downloadChunkVideo(videoUrl, startByte, endByte, ResponseType.BLOB);
  if (response.status !== 206 && response.status !== 200) {
    throw new Error("Failed to download the video chunk");
  }
  return response.data;
};

export const downloadProcessedVideo = async (processedVideo: ProcessedVideoStateData, callback: any) => {
  if (processedVideo) {
    try {
      const { video, video_full } = processedVideo.urls;
      const videoToDownload = video_full ? video_full : video;
      const blobFile: Blob = await downloadVideoInChunks(videoToDownload, CHUNK_FILE_SIZE_MB, callback);
      if (blobFile) {
        triggerFileDownloadProcess(blobFile, getFileNameFromUrl(processedVideo.urls.video));
        notifySuccess("Video inference has been downloaded successfully.");
      } else {
        notifyError("Video download failed. Please try again.");
      }
    } catch (error) {
      console.error("Error download files:", error);
      notifyError("An unexpected error occurred during the video inference download.");
    }
  } else {
    console.error("Invalid inference data for download video.");
  }
};

export const downloadProcessedVideoJson = async (processedVideo: ProcessedVideoStateData) => {
  if (processedVideo) {
    try {
      const response = await FetchService.downloadJSON(processedVideo.urls.json, ResponseType.BLOB);
      if (response && response.status === 200) {
        const data = response.data;
        if (data && data instanceof Blob) {
          const blob = new Blob([data], { type: data.type });
          triggerFileDownloadProcess(blob, getFileNameFromUrl(processedVideo.urls.json));
        }
      } else {
        console.error("Response is not a Blob:", response);
      }
    } catch (error) {
      console.error("Error download files:", error);
    }
  } else {
    console.error("Invalid inference data for download JSON.");
  }
};

const prepareCSVBlob = async (jsonData: any[], headers: CSVHeader[]): Promise<Blob> => {
  const csvData = convertToCSV(jsonData, headers, ",");
  const uFEFF = "\uFEFF"; // Byte Order Mark for UTF-8
  const csvBlob: Blob = new Blob([uFEFF, csvData], { type: "text/csv;charset=utf-8;" });
  return csvBlob;
};

export const downloadAllProcessedVideoData = async (
  videoInferences: ProcessedVideoStateData[],
  authUser: AuthUserData
) => {
  const zip = new JSZip();
  try {
    const completedVideoInferences = videoInferences.filter(
      (fileData) => fileData.state.toLowerCase() === InferenceState.COMPLETED
    );
    let fileType = "video/mp4";
    if (completedVideoInferences.length > 0) {
      await Promise.all(
        completedVideoInferences.map(async (fileData, index) => {
          try {
            let fileName = getFileNameFromUrl(fileData.urls.video);
            const response = await FetchService.downloadVideo(fileData.urls.video, ResponseType.BLOB);
            if (response && response.status === 200) {
              const data = response.data;
              if (data && data instanceof Blob) {
                const videoBlob = new Blob([data], { type: data.type });
                zip.file(fileName, videoBlob);
              }
            }

            const responseCSV = await FetchService.downloadJSON(fileData.urls.json);
            fileName = getFileNameFromUrl(fileData.urls.json);
            if (responseCSV && responseCSV.status === 200) {
              const data = responseCSV.data;
              const objectDetectionData: VideoObjectDetectionJsonData = data as VideoObjectDetectionJsonData;
              const filteredCSVData = filteredVideoCSVData(objectDetectionData, authUser);
              const csvBlob: Blob = await prepareCSVBlob(filteredCSVData, csvHeaders);
              zip.file(fileName.replace(".json", ".csv"), csvBlob);
            }

            const responseJson = await FetchService.downloadJSON(fileData.urls.json, ResponseType.BLOB);
            fileName = getFileNameFromUrl(fileData.urls.json);
            if (responseJson && responseJson.status === 200) {
              const data = responseJson.data;
              if (data && data instanceof Blob) {
                const jsonFileBlob = new Blob([data], { type: data.type });
                zip.file(fileName, jsonFileBlob);
              }
            }
          } catch (error) {
            console.error("Error download files:", error);
          }
        })
      );
      const blob = await zip.generateAsync({ type: ResponseType.BLOB });
      triggerFileDownloadProcess(blob, `Bundle video inferences-${formattedCurrentDate()}.zip`);
      notifySuccess("All completed video inferences have been downloaded successfully.");
    }
  } catch (error) {
    console.error("Error handling zip:", error);
    notifyError("An unexpected error occurred during the download all video inferences.");
  }
};
