import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { FileUploadCount, VideoFileProjectData } from "../constants/types";
import CreateVideoProjectModal from "./Common/Modals/CreateVideoProjectModal";

interface Props {
  activeBackend: boolean;
  modalOpen: boolean;
  onToggleModal: () => void;
  onHandleUploadVideo: (videoProjectData: VideoFileProjectData) => void;
  videoProjectData: VideoFileProjectData;
  setVideoProject: React.Dispatch<React.SetStateAction<VideoFileProjectData>>;
  fileUploadCount: FileUploadCount;
  setFileUploadCount: React.Dispatch<React.SetStateAction<FileUploadCount>>;
}

const CreateProject = ({
  activeBackend,
  modalOpen,
  onToggleModal,
  onHandleUploadVideo,
  videoProjectData,
  setVideoProject,
  fileUploadCount,
  setFileUploadCount,
}: Props) => {

  return (
    <React.Fragment>
      <Row className="mb-2">
        <Col lg={1} md={1}></Col>

        <Col lg={5} md={5}></Col>

        <Col lg={6} md={6}>
          <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2">
            <Button outline className="btn btn-primary-subtle" disabled={!activeBackend} onClick={onToggleModal}>
              Create Project
            </Button>
          </div>
        </Col>
        {/*
                <VideoFileUploadModal isOpen={modalOpen} fileUploadType={InferenceType.VIDEO} toggleModal={onToggleModal} title="Upload Video" handleUpload={onHandleUploadVideo} uploadPercentage={progress} handleUpdateUploadPercentage={onUpdateUploadPercentage}
                                      status={status} handleUpdateProcessFileStatus={onUpdateProcessFileStatus}/>
                                      */}

        <CreateVideoProjectModal
          isOpen={modalOpen}
          toggleModal={onToggleModal}
          title="Create Project"
          handleUpload={onHandleUploadVideo}
          videoProjectData={videoProjectData}
          setVideoProject={setVideoProject}
          fileUploadCount={fileUploadCount}
          setFileUploadCount={setFileUploadCount}
        />
      </Row>
    </React.Fragment>
  );
};

export default CreateProject;
