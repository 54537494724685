import axios, {AxiosInstance, AxiosResponse} from "axios";
import {jsonToHeaderQuery} from "../utils/utils";
import {
    INFERENCE_VIDEO_DOWNLOAD_ENDPOINT,
    IMAGE_BRANDS_INFERENCE_ENDPOINT,
    IMAGE_INFERENCE_PARAMS,
    VIDEO_INFERENCE_ENDPOINT,
    SERVER_BASE_URL_CONFIG,
    IMAGE_BRANDS_ENDPOINT,
    IMAGE_BRANDS_BATCH_INFERENCE_ENDPOINT,
    VIDEO_INFERENCE_STREAM_ENDPOINT,
} from "../utils/url_helper";

import {getUserXApiKey, headerContentType} from "../utils/auth-token-header";
import {ResponseType} from "../constants/enums/inference_enums";

class FetchService {
    private serverAPI: AxiosInstance;

    constructor() {
        this.serverAPI = axios.create({
            baseURL: SERVER_BASE_URL_CONFIG().apiBaseUrl,
            headers: {
                'Content-Type': headerContentType.JSON_TYPE,
                'X-API-KEY': getUserXApiKey()
            }
        });
    }

    // Image Inference
    getImageBrands(): Promise<AxiosResponse<any>> {
        // const response = {
        //   "official_name": "7-Eleven",
        //   "category": "Quick Service Restaurant",
        //   "regions": [
        //     "au",
        //     "uk"
        //   ],
        //   "market": "food"
        // }

        return this.serverAPI.get(`/${IMAGE_BRANDS_ENDPOINT}`, {
            headers: {
                'X-API-KEY': getUserXApiKey()
            }
        });
    }

    getImageBrandsByUserId(userId: string): Promise<AxiosResponse<any>> {
        // request body: userId
        // response: ["https://storage.cloud.google.com/scanner-app-bucket/users_data/{userId}/images/burger-king-old-new_G713T.jpg"]
        return this.serverAPI.get(`/${IMAGE_BRANDS_INFERENCE_ENDPOINT}/${userId}`, {
            headers: {
                'X-API-KEY': getUserXApiKey()
            }
        });
    }

    imageBrandsBatchInference(data: any): Promise<AxiosResponse<any>> {
        return this.serverAPI.post(`/${IMAGE_BRANDS_BATCH_INFERENCE_ENDPOINT}`, data, {
            headers: {
                'Content-Type': headerContentType.FORM_DATA_TYPE,
                'X-API-KEY': getUserXApiKey()
            }
        }
        );
    }

    imageInference = (data: any) => {
        const headerQuery = jsonToHeaderQuery(IMAGE_INFERENCE_PARAMS);
        return this.serverAPI.post(`/${IMAGE_BRANDS_INFERENCE_ENDPOINT}?${headerQuery}`, data, {
            headers: {
                'X-API-KEY': getUserXApiKey()
            }
        });
    }

    deleteImageInferenceByUserId = (userId: string)=> {
        return this.serverAPI.delete(`/${IMAGE_BRANDS_INFERENCE_ENDPOINT}/${userId}`, {
            headers: {
                'X-API-KEY': getUserXApiKey()
            }
        });
    }

    downloadImage(downloadUrl: string, responseType: ResponseType) {
        return this.serverAPI.get(`/${INFERENCE_VIDEO_DOWNLOAD_ENDPOINT}?url=${downloadUrl}`, {
            headers: {
                'X-API-KEY': getUserXApiKey(),
                'Content-Type' : headerContentType.JSON_TYPE
            },
            responseType: responseType,
        });
    }

    // End Image Inference

    // Video Inference

    getVideoInferenceByUserId(userId:string): Promise<AxiosResponse<any>> {
        return this.serverAPI.get(`/${VIDEO_INFERENCE_ENDPOINT}/${userId}`, {
            headers: {
                'X-API-KEY': getUserXApiKey()
            }
        });
    }

    uploadVideo(data: any, headers: any): Promise<AxiosResponse<any>> {
        return this.serverAPI.post(`/${VIDEO_INFERENCE_ENDPOINT}`, data, headers);
    }

    downloadVideo(downloadUrl: string, responseType: ResponseType | null = null) {
        if (responseType) {
            return this.serverAPI.get(`/${INFERENCE_VIDEO_DOWNLOAD_ENDPOINT}?url=${downloadUrl}`, {
                headers: {
                    'X-API-KEY': getUserXApiKey(),
                    'Content-Type' : headerContentType.OCTET_STREAM
                },
                responseType: responseType,
                timeout: 0, // Disable timeout for large file download
                maxContentLength: Infinity, // Allow large files
                maxBodyLength: Infinity, // Allow large bodies
            });
        }
        return this.serverAPI.get(`/${INFERENCE_VIDEO_DOWNLOAD_ENDPOINT}?url=${downloadUrl}`, {
            headers: {
                'X-API-KEY': getUserXApiKey()
            }
        });
    }

    streamVideo = (data: any): Promise<AxiosResponse<any>> => {
        return this.serverAPI.post(`/${VIDEO_INFERENCE_STREAM_ENDPOINT}`, data,{
            headers: {
                'X-API-KEY': getUserXApiKey(),
                'Content-Type': headerContentType.JSON_TYPE
            }
        });
    }

    downloadChunkVideo(downloadUrl: string, startRangeByte: number, endRangeByte: number, responseType: ResponseType):Promise<AxiosResponse<any>> {
        return this.serverAPI.get(`/${INFERENCE_VIDEO_DOWNLOAD_ENDPOINT}?url=${downloadUrl}`, {
            headers: {
                Range: `bytes=${startRangeByte}-${endRangeByte}`,
            },
            responseType: responseType
        });
    }

    getDownloadVideoSize(downloadUrl: string) {
        return this.serverAPI.head(`/${INFERENCE_VIDEO_DOWNLOAD_ENDPOINT}?url=${downloadUrl}`);
    }

    downloadJSON(downloadUrl: string, responseType: ResponseType | null = null) {
        if (responseType) {
            return this.serverAPI.get(`/${INFERENCE_VIDEO_DOWNLOAD_ENDPOINT}?url=${downloadUrl}`, {
                headers: {
                    'X-API-KEY': getUserXApiKey(),
                    'Content-Type' : headerContentType.OCTET_STREAM
                },
                responseType: responseType,
                timeout: 0, // Disable timeout for large file download
            });
        }
        return this.serverAPI.get(`/${INFERENCE_VIDEO_DOWNLOAD_ENDPOINT}?url=${downloadUrl}`, {
            headers: {
                'X-API-KEY': getUserXApiKey()
            }
        });
    }
    deleteVideo = (data: any)=> {
        return this.serverAPI.delete(`/${VIDEO_INFERENCE_ENDPOINT}`, {
            headers: {
                'X-API-KEY': getUserXApiKey()
            },
            data
        });
    }
    // End Video Inference
}

export default new FetchService();