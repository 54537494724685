import { Card, CardText, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";

interface Props {
  projectName?: string;
}

export default function ProjectCard({ projectName }: Props) {
  let navigate = useNavigate();

  const handleOpen = (e: any) => {
    const path = "/inference";
    navigate(path, { state: { projectName:  projectName} });
  };

  if (!projectName) return null

  return (
    <Card className="mb-3" style={{ maxWidth: "250px" , margin: '10px'}} body>
      <CardText
        style={{ fontSize: "2rem", padding: "50px 0 30px", textTransform: "capitalize" }}
        className="text-center"
      >
        {projectName.split('_').join(' ')}
      </CardText>
      <Button color="primary" onClick={handleOpen}>
        Open
      </Button>
    </Card>
  );
}
