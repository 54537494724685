import { changeBodyAttribute, manageBodyClass } from "./utils";

//constants
import {
    layoutTypes,
    layoutTheme,
    leftSidebarTypes,
} from "../../constants/enums/layout";

// action
import {
  changeLayoutAction,
  changeTopBarThemeAction,
  changeSidebarThemeAction,
  changeLayoutWidthAction,
  changeSidebarTypeAction,
  changeLayoutModeAction,
  changeLayoutPositionAction
} from "./reducer";

export const changeLayout: any =  (layoutType: any) => async (dispatch: any) => {
    dispatch(changeLayoutAction(layoutTypes.VERTICAL));
    changeBodyAttribute("data-layout", layoutType);
};

export const changeLayoutMode: any = (layoutMode: any, layoutType: layoutTypes) => async (dispatch: any) => {
    try {
        dispatch(changeLayoutModeAction(layoutMode));
        changeBodyAttribute("data-bs-theme", layoutMode);
        dispatch(changeTopBarThemeAction(layoutTheme.LIGHT_MODE));
        changeBodyAttribute("data-sidebar", layoutTheme.LIGHT_MODE);
    } catch (error) { }
};

export const changeTopBarTheme: any = (topBarTheme: any) => async (dispatch: any) => {
    changeBodyAttribute("data-topbar", topBarTheme);
    dispatch(changeTopBarThemeAction(topBarTheme));
  };

export const changeSidebarTheme: any =
  (sidebarTheme: any) => async (dispatch: any) => {
    changeBodyAttribute("data-sidebar", sidebarTheme);
    dispatch(changeSidebarThemeAction(sidebarTheme));
  };

export const changeLayoutWidth: any =
  (width: any) => async (dispatch: any) => {
    dispatch(changeLayoutWidthAction(width));
    dispatch(changeSidebarTypeAction(leftSidebarTypes.DEFAULT));
    changeBodyAttribute("data-layout-size", width);
};

export const changeSidebarType: any =
  (sidebarType: any, isMobile?: boolean) =>
    async (dispatch: any) => {

      switch (sidebarType) {
        case leftSidebarTypes.COMPACT:
          manageBodyClass("sidebar-enable", "remove");
          changeBodyAttribute("data-sidebar-size", "md");
          break;
        case leftSidebarTypes.ICON:
          manageBodyClass("sidebar-enable", "remove");
          changeBodyAttribute("data-sidebar-size", "sm");
          break;
        case leftSidebarTypes.DEFAULT:
            if (window.innerWidth >= 1024 && window.innerWidth <= 1366) {
                manageBodyClass("sidebar-enable", "remove");
                changeBodyAttribute("data-sidebar-size", 'sm');
            } else if (window.screen.width < 992 || window.innerWidth < 992) {
            manageBodyClass("sidebar-enable", "remove");
            changeBodyAttribute("data-sidebar-size", "lg");
          } else {
            changeBodyAttribute("data-sidebar-size", "lg");
            manageBodyClass("sidebar-enable", "add");
          }
          break;
        default:
          changeBodyAttribute("data-sidebar-size", "lg");
          manageBodyClass("sidebar-enable", "remove");
          break;
      }
      dispatch(changeSidebarTypeAction(sidebarType));
    };
