import {APP_IMAGE_INFERENCE_PAGE, APP_INFERENCE_PAGE, INFERENCE_BRAND_SUB_MENU} from "./config";

export interface RouteDefault {
    title: string;
    path: string;
}

export interface SubMenuItem {
    id: number;
    label: string;
    path: string;
    parentId: number;
}

export interface RouteMenuItem {
    id: number;
    label: string;
    icon: string;
    path: string;
    subItems?: SubMenuItem[];
}

export interface MenuItem {
    id: number;
    novidade?: boolean;
    label: string;
    icon?: string;
    path?: string;
    badge?:string;
    badgeColor?:string;
    isHeader?: boolean;
    subItems?: SubMenuItem[];
}

const MENU_ITEMS: Array<MenuItem> = [
    {
        id: 1,
        label: "Inferences",
        isHeader : true
    },
    {
        id: APP_INFERENCE_PAGE.id,
        label: APP_INFERENCE_PAGE.label,
        icon: APP_INFERENCE_PAGE.icon,
        path: APP_INFERENCE_PAGE.path,
        novidade: false,
        // subItems: [INFERENCE_BRAND_SUB_MENU]
    },
    {
        id: APP_IMAGE_INFERENCE_PAGE.id,
        label: APP_IMAGE_INFERENCE_PAGE.label,
        icon: APP_IMAGE_INFERENCE_PAGE.icon,
        path: APP_IMAGE_INFERENCE_PAGE.path
    }
];

export { MENU_ITEMS };
