import FetchService from "../services/FetchService";
import {ResponseType} from "../constants/enums/inference_enums";
import {notifyError, notifySuccess} from "../services/NotificationService";
import JSZip from "jszip";
import {formattedCurrentDate, triggerFileDownloadProcess} from "./utils";
import {ImageInferenceData} from "../constants/types";

export const downloadInferenceImage = async (fileUrl: string, fileName: string) => {
    try {
        const response = await FetchService.downloadImage(fileUrl, ResponseType.BLOB);
        if (response && response.status === 200) {
            const data = response.data;
            if (data && data instanceof Blob) {
                const blob = new Blob([data], {type: data.type});
                triggerFileDownloadProcess(blob, fileName);
                notifySuccess("Inference image has been downloaded successfully.");
            }
        } else {
            notifyError("Unable to download the image. Please try again later.")
            console.error('Response is not a Blob:', response);
        }
    } catch (error) {
        notifyError("Unable to download the image. Please try again later.")
        console.error("Error download file:", error);
    }
}

export const downloadAllInferenceImages = async (imageInferences: ImageInferenceData[]) => {
    const zip = new JSZip();
    try {
        await Promise.all(imageInferences.map(async (fileData: ImageInferenceData, index: number) => {
            try {
                const response = await FetchService.downloadImage(fileData.url, ResponseType.BLOB);
                if (response && response.status === 200) {
                    const data = response.data;
                    if (data && data instanceof Blob) {
                        const blob = new Blob([data], {type: data.type});
                        zip.file(fileData.name, blob);
                    }
                } else {
                    console.error('Response is not a Blob:', response);
                }
            } catch (error) {
                console.error("Error download files:", error);
            }

        }));

        const blob = await zip.generateAsync({ type: 'blob' });
        triggerFileDownloadProcess(blob, `Bundle inference images-${formattedCurrentDate()}.zip`);
        notifySuccess("All inference images have been downloaded successfully.");

    } catch (error) {
        console.error("Error handling zip:", error);
        notifyError('Unable to download inference images. Please try again later.')
    }
}

