import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React from "react";

interface DeleteModalProps {
    isModalOpen: boolean;
    title: string;
    bodyMessage: string;
    deleteActionLabel: string;
    toggleModal: () => void;
    data: any;
    handleDeleteAction: (item: any) => Promise<void>;
}

export const DeleteModal = ({isModalOpen, title, bodyMessage, deleteActionLabel, toggleModal, data, handleDeleteAction}: DeleteModalProps) => {
    return (
        <React.Fragment>
            <Modal isOpen={isModalOpen} toggle={toggleModal} backdrop={"static"}>
                <ModalHeader toggle={toggleModal} tag="h5">{title}</ModalHeader>
                <ModalBody>
                    <p>
                        Are you sure you want to delete{" " + bodyMessage}?
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="btn btn-light" onClick={toggleModal}>
                        Cancel
                    </Button>
                    <Button color="btn btn-danger" onClick={() => handleDeleteAction(data)}>
                        {deleteActionLabel}
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};