import React from 'react';
import Chart from 'react-apexcharts';
import {
    BoundingBoxInstance, CSVDataProps,
    ScannerParameters,
    VideoObjectDetectionJsonData
} from "../../../../constants/types";
import {Row} from "reactstrap";

// Import scss
import "../../../../assets/scss/apex-react-chart.scss";
import {filteredVideoCSVData} from "../../../../utils/video_inference_utils";
import {AuthUserData} from "../../../../constants/models/Models";
import SummaryTable from "./SummaryTable";
import {ApexOptions} from "apexcharts";

interface ObjectDetectionJsonDataProps {
    videoName: string;
    categoryName: string;
    xAxisLabel: string;
    yAxisLabel: string;
    parameters?: ScannerParameters;
    objectDetectionJsonData: VideoObjectDetectionJsonData | null;
    authUser: AuthUserData;
}

interface ChartDataProps {
    brand: string;
    market: string;
    regions: any;
}

const StackedBarChartTable = ({videoName, categoryName, xAxisLabel, yAxisLabel, objectDetectionJsonData, parameters, authUser}: ObjectDetectionJsonDataProps) => {
    const filteredCSVData = filteredVideoCSVData(objectDetectionJsonData!, authUser, parameters);
    const uniqueAdNumbers = new Set();
    filteredCSVData.forEach(csvData => uniqueAdNumbers.add(csvData.adNumber));
    const uniqueAdNumberOptions = [...new Set(filteredCSVData.map(item => "Ad " + item.adNumber))];
    const uniqueBrandNames = [...new Set(filteredCSVData.map(item => item.brandName))];
    const stackedBarSeries = uniqueBrandNames.map(brand => {
        return {
            name: brand,
            data: uniqueAdNumberOptions.map(adNumber => {

                const eachData: CSVDataProps | undefined = filteredCSVData.find(item => item.brandName === brand && "Ad " + item.adNumber === adNumber);
                return eachData ? eachData.brandCount : 0;
            })
        };
    });

    const totalAds = uniqueAdNumbers.size > 0 ? uniqueAdNumbers.size : '';

    const selectedCountry: any = parameters?.country ? parameters?.country?.value: null;
    const categories: any[] = [];
    parameters?.category?.forEach((eachItem) => {
        categories.push(eachItem.value);
    })

    const generateColors = (count: number): string[] => {
        const colors: string[] = [];
        const letters = "0123456789ABCDEF";
        for (let i = 0; i < count; i++) {
            let color = '#';
            for (let j = 0; j < 6; j++) {
                color += letters[(Math.floor(Math.random() * 16))];
            }
            colors.push(color);
        }
        return colors;
    };

    const filterChartData = (data: ChartDataProps[], country: string, categories: any[]) => {
        return data.filter(chartData => {
            if (country && categories.length > 0) {
                return chartData.regions.includes(country) && categories?.includes(chartData.market);
            } else if (country && categories.length === 0) {
                return chartData.regions.includes(country);
            }
            return true; // if no model or categories, return the original data
        });
    };

    const allChartData: ChartDataProps[] = [];
    const chartDataBoundingBoxes: { [key: string]: number } = {};
    objectDetectionJsonData?.results?.forEach((frame) => {
        const eachBoundingBoxes: BoundingBoxInstance[] | undefined = frame.inference?.merged?.instances;
        eachBoundingBoxes?.forEach((eachBoundingBox) => {
            allChartData.push({brand:eachBoundingBox.brand, market: eachBoundingBox.market, regions: eachBoundingBox.regions})
        });
    });


    const filteredChartData = filterChartData(allChartData, selectedCountry, categories);

    filteredChartData.forEach((data) => {
        chartDataBoundingBoxes[data.brand] = (chartDataBoundingBoxes[data.brand] || 0) + 1;
    })

    const uniqueBrands = Object.keys(chartDataBoundingBoxes).length != 0 ? Object.keys(chartDataBoundingBoxes).length: "";
    const sortedBrands = Object.keys(chartDataBoundingBoxes).sort((a, b) => chartDataBoundingBoxes[b] - chartDataBoundingBoxes[a]);

    const mostRepeatedBrandFrequency = chartDataBoundingBoxes[sortedBrands[0]];
    const leastRepeatedBrandFrequency = chartDataBoundingBoxes[sortedBrands[sortedBrands.length - 1]];

    const mostBrands = sortedBrands.filter(brand => chartDataBoundingBoxes[brand] === mostRepeatedBrandFrequency);
    const leastBrands = sortedBrands.filter(brand => chartDataBoundingBoxes[brand] === leastRepeatedBrandFrequency);
    const topMostThreeBrands = mostBrands.length > 1 ? mostBrands.slice(0, 3).join(', ') : mostBrands[0];
    const leastThreeBrands = leastBrands.length > 1 ? leastBrands.slice(0, 3).join(', ') : leastBrands[0];


    // Generate colors for each category
    // const colors = generateColors(Object.keys(chartDataBoundingBoxes).length);
    const colors = [
        "#038edc",
        "#564ab1",
        "#f1734f",
        "#f7cc53",
        "#51d28c",
        "#237666",
        "#534287",
        "#2EFC8E",
        "#4774C2",
        "#583FFC",
        "#1AD5A1",
        "#1A79C2",
        "#79554A",
        "#9DF00E",
        "#04CA17",
        "#EEC31B",
        "#E97B6A",
        "#BFE377",
        "#015D8F",
        "#C5EA7A",
        "#E1BBAD",
        "#A993F8",
        "#C89C57",
        "#210506",
        "#BCFFD0",
        "#209D23",
        "#4C4410",
        "#5B712A",
        "#96DF8A",
        "#E60211",
        "#83A4F9",
        "#26AA14",
        "#29F4CC",
        "#E03EAD",
        "#614C8B",
        "#2D6A26",
        "#8C0BC3",
        "#6F1528",
        "#6F0A7A",
        "#BC225A",
        "#DC2F8C",
        "#78042F",
        "#E298E7",
        "#8993E1",
        "#6858A5",
        "#214F94",
        "#FB2355",
        "#5D8BE5",
        "#3F41A8",
        "#E982A2",
        "#41BAB9",
        "#0A2BB1",
        "#DC6DF1",
        "#766BBE",
        "#7B0117",
        "#A3E3D0",
        "#930ACB",
        "#4E972A",
        "#151C14",
        "#DCC8F8",
        "#C79CCA",
        "#29123B",
        "#576C50",
        "#EE935B",
        "#237E9E",
        "#79751C",
        "#4068D2",
        "#BBD644",
        "#D14B4A",
        "#9D8711",
        "#9EFB6B",
        "#F09055",
        "#232CC1",
        "#9C31EB",
        "#AC27F3",
        "#B3888B",
        "#B068AC",
        "#903C32",
        "#199795",
        "#C59B32",
        "#2FE732",
        "#4BC441",
        "#9339B8",
        "#2C717A",
        "#15435D",
        "#4948AD",
        "#6D312B",
        "#F1A5E8",
        "#F25DFB",
        "#B4E9AD",
        "#FB129D",
        "#C89EBA",
        "#4C8A58"
    ];

    const stackedBarChartData = {
        options: {
            chart: {
                stacked: true,
                toolbar: {
                    show: true,
                    export: {
                        png: {
                            filename: videoName,
                        },
                        svg: {
                            filename: videoName,
                        }
                    }
                },
                // events: {
                //     dataPointSelection: (event: any, chartContext: any, config: any) => {
                //         const selectedBarIndex = config.dataPointIndex;
                //         const selectedSeriesIndex = config.seriesIndex;
                //         const selectedValue = chartData.stackedBarSeries[selectedSeriesIndex].data[selectedBarIndex];
                //         const selectedCategory = chartData.options.xaxis.categories[selectedBarIndex];
                //     }
                // }
            },
            plotOptions: {
                bar: {
                    horizontal: false, // Set to false for a vertical bar
                    // columnWidth: "80%",
                    // distributed: !0
                },
            },
            dataLabels: {
                enabled: !1
            },
            legend: {
                position: "top",
                horizontalAlign: "left",
                offsetX: 40,
                // onItemClick: {
                //     toggleDataSeries: false, // Disable legend click
                // },
                // onItemHover: {
                //     highlightDataSeries: false, // Optionally disable highlight on hover
                // },
                show: true
            },
            // title: {
            //     text: "100% Stacked Bar",
            //     style: {
            //         fontWeight: 600,
            //     },
            // },
            xaxis: {
                categories: uniqueAdNumberOptions,
                title: {
                    text: xAxisLabel,
                },
                labels: {
                    style: {
                        colors: colors,
                        fontSize: "12px"
                    }
                },
            },
            yaxis: {
                title: {
                    text: yAxisLabel,
                    style: {
                        fontSize: '12px',
                    }
                }
            },
            fill: {
                opacity: 0.8,
            },
            colors: colors
        } as ApexOptions,
        series: stackedBarSeries
    };

    return (
        <React.Fragment>
            <Row className="gx-lg-5">
                <div className="col-md-auto">
                    <div>
                        <p className="text-muted mb-2">Total Brands</p>
                        <h4 className="mb-0">
                            {uniqueBrands}
                        </h4>
                    </div>
                </div>
                <div className="col-md-auto">
                    <div>
                        <p className="text-muted mb-2">Total Ads</p>
                        <h4 className="mb-0">
                            {totalAds}
                        </h4>
                    </div>
                </div>
                <div className="col-md align-self-end">
                    <div className="text-md-end mt-4 mt-md-0">
                        <ul className="list-inline main-chart mb-0">
                            <li className="list-inline-item chart-border-left me-0 border-0">
                                <h4 className="text-primary my-1">
                                    {topMostThreeBrands}
                                    <span
                                        className="text-muted d-inline-block fw-normal font-size-13 ms-2">Most {categoryName}/s</span>
                                </h4>
                            </li>
                            {" "}
                            <li className="list-inline-item chart-border-left me-0">
                                <h4 className="my-1">
                                    {leastThreeBrands}
                                    <span className="text-muted d-inline-block fw-normal font-size-13 ms-2">Least {categoryName}/s</span>
                                </h4>
                            </li>
                            {" "}
                        </ul>
                    </div>
                </div>
            </Row>
            <Row>
                {filteredCSVData.length > 0 &&
                    <div className="apex-charts" dir="ltr">
                        <Chart options={stackedBarChartData.options} series={stackedBarChartData.series} type="bar" height={550}/>
                    </div>
                }
            </Row>
            <Row>
                <SummaryTable summaryData={filteredCSVData}/>
            </Row>
        </React.Fragment>
    );
}
export default StackedBarChartTable;