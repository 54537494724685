import React, {useEffect, useState} from "react";
import {Container, Row, Col, Form, Alert, Input, Label, Button} from "reactstrap";
import {Link} from "react-router-dom";

//import images
import logoDark from "../../assets/images/scanner-logo.png";
import {
    APP_CONFIG_DEFAULT,
    AUTH_FORGOT_PASSWORD_PAGE
} from "../../config/config";
import {
    DEAKIN_NAME,
    DEAKIN_URL,
    IHT_DEAKIN_NAME,
    IHT_DEAKIN_URL,
    IISRI_DEAKIN_NAME,
    IISRI_DEAKIN_URL
} from "../../utils/url_helper";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {userForgetPassword} from "../../slices/Auth/Forgetpwd/thunk";

const ForgotPassword = () => {
    document.title = AUTH_FORGOT_PASSWORD_PAGE.label + " | " + APP_CONFIG_DEFAULT.title;

    const [email, setEmail] = useState<string>('');
    const [resetSent, setResetSent] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const dispatch = useDispatch();

    const selectResetPasswordProperties = createSelector(
        (state: any) => state.forgetPassword,
        (layout) => ({
            forgetPassword:layout
        })
    );
    // Inside your component
    const {forgetPassword} = useSelector(selectResetPasswordProperties);

    const handleReset = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            dispatch(userForgetPassword({email}));
            setResetSent(true);
            setError(null);
        } catch (error) {
            // setError(error.message);
        }
    };

    useEffect(() => {
        // dispatch(apiError(""));
        if (forgetPassword && !forgetPassword.forgetError) {
            setEmail("");
        }
    }, [dispatch, forgetPassword]);

    return (
        <React.Fragment>
            <div className="authentication-bg min-vh-100">
                <div className="bg-overlay bg-white"></div>
                <Container>
                    <div className="d-flex flex-column min-vh-100 px-3 pt-4">
                        <Row className="justify-content-center my-auto">
                            <Col md={8} lg={6} xl={4}>

                                <div className="text-center py-5">
                                    <div className="mb-4 mb-md-5">
                                        <Link to={APP_CONFIG_DEFAULT.path} className="d-block auth-logo">
                                            <img src={logoDark} alt="" height="22" className="auth-logo-dark" />
                                        </Link>
                                    </div>
                                    <div className="text-muted mb-4">
                                        <h5 className="">{AUTH_FORGOT_PASSWORD_PAGE.label}</h5>
                                        <p>Forgot your password with {APP_CONFIG_DEFAULT.title}?</p>
                                    </div>
                                    {forgetPassword && forgetPassword.forgetSuccessMsg!='' && forgetPassword.forgetError=='' &&
                                        <Alert color="success" className="text-center mb-4" role="alert">
                                            Password reset link has been sent to your mailbox!
                                        </Alert>
                                    }
                                    {forgetPassword && forgetPassword.forgetError!='' &&
                                        <Alert color="danger" className="text-center mb-4" role="alert">
                                            {forgetPassword.forgetSuccessMsg.message!}
                                        </Alert>
                                    }

                                    <Form onSubmit={handleReset}>
                                        <div className="form-floating form-floating-custom text-start mb-3">
                                            <Input type="email" className="form-control" id="input-email" placeholder="Enter Email"
                                                   value={email}
                                                   onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <Label htmlFor="input-email">Email</Label>
                                            <div className="form-floating-icon">
                                                <i className="uil uil-envelope-alt"></i>
                                            </div>
                                        </div>

                                        <div className="mt-3">
                                            <Button type="submit" className="btn btn-info w-100">Send Link</Button>
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12}>
                                <div className="text-center text-muted p-4">
                                    <p className="mb-0"> &copy; {new Date().getFullYear()} {APP_CONFIG_DEFAULT.title}. Developed by <Link to={IISRI_DEAKIN_URL} target="_blank" rel="noreferrer" className="text-reset">{IISRI_DEAKIN_NAME}</Link>
                                        {" "} and <Link to={IHT_DEAKIN_URL} target="_blank" rel="noreferrer" className="text-reset">{IHT_DEAKIN_NAME}</Link>,
                                        {" "}<Link to={DEAKIN_URL} target="_blank" rel="noreferrer" className="text-reset">{DEAKIN_NAME}</Link>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ForgotPassword;