import React from "react";
import {Table} from "reactstrap";
import {
    CSVDataProps, csvHeaders,
} from "../../../../constants/types";

interface SummaryTableProps {
    summaryData: CSVDataProps[];
}
const SummaryTable = ({summaryData}: SummaryTableProps) => {
    return (
        <React.Fragment>
            <div className="table-responsive react-table">
                <Table className="table align-middle table-nowrap table-hover mb-0">
                    <thead className="table-light table-nowrap">
                    <tr>
                        {csvHeaders.filter(header => header.code !="user").map(eachHeader => eachHeader.label).map((eachHeader) => (
                            <th key={eachHeader} scope="col">{eachHeader}</th>
                        ))}
                    </tr>

                    </thead>
                    <tbody>
                    {summaryData.length > 0 ? (
                        summaryData.map((eachRow, index) => (
                            <tr key={index}>
                                {csvHeaders.filter(header => header.code != "user").map((eachHeader) => (
                                    <td key={index}>{eachRow[eachHeader.code as keyof CSVDataProps]}</td>
                                ))}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={csvHeaders.filter(header => header.code != "user").length} className="table-data-text-align">
                                <div className="table-data-not-available">
                                    No data available!
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    );
}
export default SummaryTable;