import {
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    getAuth,
    GoogleAuthProvider,
    onAuthStateChanged,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithCredential,
    signInWithEmailAndPassword,
    signOut,
    updateEmail,
    updatePassword,
    updateProfile,
    User,
    UserCredential
} from 'firebase/auth';
import {collection, doc, getDoc, getDocs, getFirestore, setDoc, updateDoc} from 'firebase/firestore';
import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage';
import {FirebaseApp} from "@firebase/app";
import {DocumentReference, Firestore} from "@firebase/firestore";
import {FirebaseStorage} from "@firebase/storage";

import {Auth} from "@firebase/auth";
import {AUTH_LOGIN_PAGE} from "../config/config";
import {authErrorMessages} from "../config/auth_config";
import {FirebaseAuthErrorCode} from "../constants/enums/inference_enums";
import {AuthUserData} from "../constants/models/Models";
import {RoleType, UserStatus} from "../constants/enums/Auth";
import {formatDate} from "../utils/utils";
import AuthUserService from "./AuthUserService";
import AuthAPIKeyService from "./AuthAPIKeyService";

class FirebaseAuthService {
    private auth: Auth;
    private store: Firestore
    private storage: FirebaseStorage;
    // private db: Database;

    constructor(app: FirebaseApp) {
        this.auth = getAuth(app);
        this.store = getFirestore(app);
        this.storage = getStorage(app);
        // this.db = getDatabase(app);

        this.setupAuthStateListener();
    }

    private setupAuthStateListener() {
        // Set up onAuthStateChanged listener
        onAuthStateChanged(this.auth, async (user: User | null) => {
            if (user && localStorage.getItem("authUser")) {
                const userDetails = await this.getCurrentUserDetails(user.uid)
                this.setLoggedInUser(user, userDetails);
            } else {
                this.removeLoggedInUser();
            }
        });
    }

    /**
     * Registers the user with given details
     */
    registerUser = async (email: string, password: string) => {
        try {
            const userCredential:UserCredential = await createUserWithEmailAndPassword(this.auth, email, password);
            await sendEmailVerification(userCredential.user)
            await this.addNewUser(userCredential.user)

            return userCredential.user;
        } catch (error) {
            throw this._handleError(error);
        }
    };

    /**
     * Update user password
     */
    updateUserPassword = async (password: string) => {
        try {
            const currentUser: User | null = this.auth?.currentUser;
            if (!currentUser) {
                console.error("No authenticated user found.");
                return;
            }
            await updatePassword(currentUser, password);
        } catch (error) {
            throw this._handleError(error);
        }
    };

    /**
     * Update user email
     */
    updateUserEmail = async (email: string) => {
        try {
            const currentUser: User | null = this.auth?.currentUser;
            if (!currentUser) {
                console.error("No authenticated user found.");
                return;
            }
            await updateEmail(currentUser, email);
        } catch (error) {
            throw this._handleError(error);
        }
    };
    /**
     * Send email verification link
     */

    sendEmailVerificationLink = () => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            // sendEmailVerification(user, {url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT})
            sendEmailVerification(user)
                .then(() => {
                    // Email verification link sent successfully
                    // Send email verification
                })
                .catch((error) => {
                    // An error occurred while sending the email verification link
                    console.error("Error sending email verification link:", error);
                });
        } else {
            console.error("No user is currently signed in.");
        }
    };

    /**
     * Registers the user with given details
     */
    editProfileAPI = async (email: any, password: any) => {
        try {
            const userCredential:UserCredential = await createUserWithEmailAndPassword(this.auth, email, password);
            return userCredential.user;
        } catch (error) {
            throw this._handleError(error);
        }
    };

    /**
     * Login user with given details
     */

    loginUser = async (email: string, password: string) => {
        try {
            const userCredential = await signInWithEmailAndPassword(this.auth, email, password);
            const userDetails = await this.getCurrentUserDetails(userCredential.user.uid)

            // update user last login date
            const user: any = userCredential.user;
            const updateAuthUserData = {
                lastLoginAt: user.metadata.lastLoginAt ? Number(user.metadata.lastLoginAt) : Date.now(),
            };
            const userRef: DocumentReference = doc(this.store, "users", this.auth.currentUser!.uid);
            await updateDoc(userRef, updateAuthUserData);
            // End User Update

            this.setLoggedInUser(userCredential.user, userDetails);
            return JSON.stringify(userCredential.user);
        } catch (error) {
            throw this._handleError(error);
        }
    };

    /**
     * forget Password user with given details
     */
    forgotUserPassword = async (email: string) => {
        try {
            await sendPasswordResetEmail(this.auth, email, {
                url: window.location.protocol + "//" + window.location.host + AUTH_LOGIN_PAGE.path,
            });
            return true; // Password reset email sent successfully
        } catch (error) {
            throw this._handleError(error);
        }
    };

    /**
     * Logout the user
     */
    logout = async () => {
        try {
            await signOut(this.auth);
            this.removeLoggedInUser();
            return true;
        } catch (error) {
            throw this._handleError(error);
        }
    };

    /**
     * Update profile settings
     */

    updateUserSettingProfile = async (userData: AuthUserData, selectedPhoto: File) => {
        try {
            if (!this.auth) {
                console.error("No authentication found.");
                return;
            }
            const currentUser: User | null = this.auth.currentUser;

            if (!currentUser) {
                console.error("No authenticated user found.");
                return;
            }

            const authUser: AuthUserData = AuthUserService.getLoggedAuthorizedUser();
            let updateUserData: any = {};

            if (selectedPhoto && selectedPhoto instanceof File) {
                updateUserData["photoURL"] = await this.updateProfilePhoto(selectedPhoto);
            }

            if (currentUser.displayName != userData.displayName) {
                updateUserData["displayName"] = userData.displayName;
                await updateProfile(currentUser, {displayName: userData.displayName});
            }

            // We can update user email if needed
            if (currentUser.email != userData.email) {
                updateUserData["email"] = userData.email;
                await updateEmail(currentUser, userData.email!);
            }

            if (userData.phoneNumber && authUser.phoneNumber != userData.phoneNumber) {
                updateUserData["phoneNumber"] = userData.phoneNumber;
            }

            if (userData.designation && authUser.designation != userData.designation) {
                updateUserData["designation"] = userData.designation;
            }

            if (userData.description && authUser.description != userData.description) {
                updateUserData["description"] = userData.description;
            }
            const userAddressInfo = userData.addressInfo;
            if (userAddressInfo) {
                updateUserData["addressInfo"] = userAddressInfo;
            }

            updateUserData["modifiedDate"] = Date.now();
            const userRef: DocumentReference = doc(this.store, "users", this.auth.currentUser!.uid);
            await updateDoc(userRef, updateUserData);
            // update the onAuthStateChanged listener
            this.setupAuthStateListener();
            return "Your profile has been updated successfully";
        } catch (error) {
            throw this._handleError(error);
        }
    };

    /**
     * change the status, roles, and x-API-KEY of user by Admin
     */

    updateUserStatusAndXApiKey = async (userData: AuthUserData, userAction: UserStatus, generatedApiKey?: string) => {
        try {
            if (!this.auth) {
                console.error("No authentication found.");
                return;
            }

            let statusMsg = userAction?.toLowerCase();
            if (userAction.toLowerCase() === UserStatus.ACTIVE.toLowerCase()) {
                statusMsg = "activated";
                const updateAuthUser = { status: UserStatus.ACTIVE, accessXApiKey: generatedApiKey, modifiedDate: Date.now()};
                const userRef: DocumentReference = doc(this.store, "users", userData.uid);
                await updateDoc(userRef, updateAuthUser);
            } else if (userAction.toLowerCase() === UserStatus.SUSPENDED.toLowerCase()) {
                const updateAuthUser = { status: UserStatus.SUSPENDED, accessXApiKey: generatedApiKey, modifiedDate: Date.now()};
                const userRef: DocumentReference = doc(this.store, "users", userData.uid);
                await updateDoc(userRef, updateAuthUser);
            }
            // update the onAuthStateChanged listener
            this.setupAuthStateListener();
            return `The user has been ${statusMsg} successfully`;
        } catch (error) {
            throw this._handleError(error);
        }
    };
    updateUserRoles = async (userData: AuthUserData, userRoles: Array<RoleType>) => {
        try {
            if (!this.auth) {
                console.error("No authentication found.");
                return;
            }

            const updateAuthUser = { status: UserStatus.ACTIVE, roles: userRoles, modifiedDate: Date.now()};
            const userRef: DocumentReference = doc(this.store, "users", userData.uid);
            await updateDoc(userRef, updateAuthUser);
            // update the onAuthStateChanged listener
            this.setupAuthStateListener();
            return `The user roles has been updated successfully`;
        } catch (error) {
            throw this._handleError(error);
        }
    };

    updateProfilePhoto = async (profile: File) => {
        try {
            if (!this.auth) {
                console.error("No authentication found.");
                return;
            }
            if (!this.storage) {
                console.error("No authentication storage found.");
                return;
            }
            const currentUser: User | null = this.auth.currentUser;
            if (!currentUser) {
                console.error("No authenticated user found.");
                return;
            }
            const storageRef = ref(this.storage, `profile/${currentUser.uid}.${profile.type.split('/')[1]}`);
            const snapshot = await uploadBytes(storageRef, profile);
            const downloadURL = await getDownloadURL(snapshot.ref);
            await updateProfile(currentUser, {photoURL: downloadURL});
            return downloadURL;
        } catch (error) {
            throw this._handleError(error);
        }
    }

    private addNewUser = async (user: any) => {
        let accessXApiKey = '';

        // if (user?.email.includes('deakin.edu.au')) {
        //     accessXApiKey = await this.generateAuthApiKey(user.email);
        // }

        const userData: AuthUserData = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName ? user.displayName:"",
            photoURL: user.photoURL ? user.photoURL:"",
            phoneNumber: user.phoneNumber?user.phoneNumber:"",
            roles: [RoleType.USER],
            status: accessXApiKey !== "" ? UserStatus.ACTIVE : UserStatus.PENDING,
            accessXApiKey: accessXApiKey,
            designation: "",
            description: "",
            createdDate: user.metadata?.createdAt?Number(user.metadata.createdAt):Date.now(),
            lastLoginAt: user.metadata?.createdAt?Number(user.metadata.lastLoginAt):Date.now(),
            modifiedDate: user.metadata?.createdAt?Number(user.metadata.createdAt):Date.now()
        };

        try {
            const userDocRef = doc(this.store, 'users', user.uid);
            await setDoc(userDocRef, userData);
            return {user, userData};
        } catch (error) {
            throw this._handleError(error);
        }
    };

    generateAuthApiKey = async (userEmail: string) => {
        try {
            const requestData = {owner: userEmail};
            const response = await AuthAPIKeyService.generateApiKey(requestData);
            if (response && response.status == 201) {
                const data = response.data; // {"api_key": api_key, "message": "Please save this secret key somewhere safe and accessible."}
                const generatedApiKey = data["data"] ? data["data"]["api_key"] : data["api_key"]
                // check the generated API Key is valid
                const validAccessXApiKey = await this.isValidAccessXApiKey(generatedApiKey);
                if (validAccessXApiKey) {
                    return generatedApiKey;
                }
                return ''
            }
        } catch (error) {
            return ''
        }
    }

    isValidAccessXApiKey = async (accessXApiKey: string) => {
        try {
            const response = await AuthAPIKeyService.validateApiKey({api_key: accessXApiKey});
            if (response && response.status == 200) {
                const data = response.data;
                return data['data'] ? data['data']['is_valid'] : false;
            } else if (response && (response.status == 400 || response.status == 401)) {
                return false;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    getUserDetails = async () => {
        const authUsers: AuthUserData[] = [];

        try {
            const isLoggedUserSuperAdmin = AuthUserService.isLoggedUserSuperAdmin();
            const isLoggedUserAdmin = AuthUserService.isLoggedUserAdmin();
            const querySnapshot = await getDocs(collection(this.store, 'users'));
            // const docs = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
            querySnapshot.docs.forEach((eachData) => {
                const eachDocumentData = eachData.data();
                const authUserData: AuthUserData = {
                    uid: eachDocumentData.uid,
                    email: eachDocumentData.email,
                    displayName: eachDocumentData.displayName ? eachDocumentData.displayName : "",
                    photoURL: eachDocumentData.photoURL ? eachDocumentData.photoURL : "",
                    phoneNumber: eachDocumentData.phoneNumber,
                    roles: eachDocumentData.roles,
                    accessXApiKey: eachDocumentData.accessXApiKey,
                    status: eachDocumentData.status,
                    designation: eachDocumentData.designation,
                    description: eachDocumentData.description,
                    createdDate: eachDocumentData.createdDate && typeof eachDocumentData.createdDate === 'number' ? formatDate(new Date(eachDocumentData.createdDate)): null,
                    lastLoginAt: eachDocumentData.lastLoginAt && typeof eachDocumentData.lastLoginAt === 'number'  ? formatDate(new Date(eachDocumentData.lastLoginAt)): null,
                    modifiedDate: eachDocumentData.modifiedDate && typeof eachDocumentData.modifiedDate === 'number'  ? formatDate(new Date(eachDocumentData.modifiedDate)): null,
                };
                if (isLoggedUserSuperAdmin) {
                    authUsers.push(authUserData);
                } else if (!AuthUserService.isUserSuperAdmin(authUserData) && isLoggedUserAdmin) {
                    authUsers.push(authUserData);
                }
            })

            return authUsers;
        } catch (error) {
            throw this._handleError(error);
        }
    };

    getCurrentUserDetails = async (userId: string) => {
        try {
            return this.getDocument("users", userId);
        } catch (error) {
            throw this._handleError(error);
        }
    };

    private getDocument = async (yourCollection: string, documentId: string) => {
        try {
            const documentRef: DocumentReference = doc(this.store, yourCollection, documentId);
            const docSnapshot = await getDoc(documentRef);
            return docSnapshot.data();

        } catch (error) {
            throw this._handleError(error);
        }
    };

    setLoggedInUser = (user: any, userDetails: any) => {
        const firebaseAuthUser: AuthUserData = {
            // User Credentials info
            uid: user.uid,
            email: user.email,
            emailVerified: user.emailVerified,
            displayName: user.displayName!,
            isAnonymous: user.isAnonymous,
            photoURL: user.photoURL,
            phoneNumber: user.phoneNumber?user.phoneNumber:userDetails.phoneNumber,
            roles: userDetails.roles,
            accessXApiKey: userDetails.accessXApiKey,

            // Custom User Info: Store in Firestore
            status: userDetails.status,
            designation: userDetails.designation,
            description: userDetails.description,
            addressInfo: userDetails.addressInfo,
            createdDate: Number(user.metadata.createdAt),
            lastLoginAt: Number(user.metadata.lastLoginAt),
            modifiedDate: userDetails.modifiedDate
        }
        localStorage.setItem("authUser", JSON.stringify(firebaseAuthUser));
    };

    removeLoggedInUser = () => {
        localStorage.removeItem("authUser");
    };

    private _handleError(error: any) {
        if (error && error.code && error.message) {
            const errorMessage = authErrorMessages[error.code as FirebaseAuthErrorCode];
            return {code: error.code, message: errorMessage};
        } else if (error && error.code && error.message==undefined) {
            const errorMessage = authErrorMessages[error.code as FirebaseAuthErrorCode];
            return {code: error.code, message: errorMessage};
        } else {
            return {code: 'unknown', message: 'An unknown error occurred'};
        }
    }
}

export default FirebaseAuthService;
