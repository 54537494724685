import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import {APP_CONFIG_DEFAULT} from "../config/config";
import {
    DEAKIN_NAME,
    DEAKIN_URL,
    IHT_DEAKIN_NAME,
    IHT_DEAKIN_URL,
    IISRI_DEAKIN_NAME,
    IISRI_DEAKIN_URL
} from "../utils/url_helper";

const Footer = () => {
  return (
      <React.Fragment>
        <footer className="footer">
          <Container fluid={true}>
            <Row>
              <Col sm={6}>{new Date().getFullYear()} © {APP_CONFIG_DEFAULT.title}</Col>
              <Col sm={6}>
                <div className="text-sm-end d-none d-sm-block">
                  Developed by <Link to={IISRI_DEAKIN_URL} target="_blank" rel="noreferrer" className="text-reset">{IISRI_DEAKIN_NAME}</Link>
                    {" "} and <Link to={IHT_DEAKIN_URL} target="_blank" rel="noreferrer" className="text-reset">{IHT_DEAKIN_NAME}</Link>,
                    {" "}<Link to={DEAKIN_URL} target="_blank" rel="noreferrer" className="text-reset">{DEAKIN_NAME}</Link>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </React.Fragment>
  );
};

export default Footer;