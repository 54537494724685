import React, { useMemo, useState } from "react";
import { ImageInferenceData, ReactDataTableColumnProps } from "../../constants/types";
import { Button } from "reactstrap";
import { capitalize, formatBytes, formatDate } from "../../utils/utils";

//Import Light box
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/counter.css";
import { ZipDownload } from "./ZipDownload";
import { InferenceState } from "../../constants/enums/inference_enums";
import { UserStatus } from "../../constants/enums/Auth";
import { AuthUserData } from "../../constants/models/Models";
import AuthUserService from "../../services/AuthUserService";
import { ReactDataTableWithPagination } from "../Common/DataTable/ReactDataTableWithPagination";
import { downloadInferenceImage } from "../../utils/image_inference_utils";
import { CustomDownload } from "./CustomDownload";

interface ImageDataTableLightBoxProps {
  activeBackend: boolean;
  imageInferences: ImageInferenceData[];
  onDeleteImageInference: (inferenceData: ImageInferenceData) => void;
}

const ImageDataTableLightBox = ({
  activeBackend,
  imageInferences,
  onDeleteImageInference,
}: ImageDataTableLightBoxProps) => {
  const [isOpenLightbox, setIsOpenLightbox] = useState<boolean>(false);
  const [fileIndex, setFileIndex] = useState<number>(0);
  const [finite, setFinite] = useState(true);

  const authUser: AuthUserData = AuthUserService.getLoggedAuthorizedUser();
  const pendingUser = authUser && authUser.status?.toLowerCase() === UserStatus.PENDING.toLowerCase();

  const openLightbox = (index: number) => {
    setFileIndex(index);
    setIsOpenLightbox(true);
  };

  const DATA_COLUMNS: ReactDataTableColumnProps[] = useMemo(
    () => [
      {
        Header: "Name",
        Footer: "Name",
        accessor: "name",
        disableFilters: true,
        filterable: true,
        Cell: (cellProps: any) => {
          const eachCellData = cellProps.row.original;
          const status = cellProps.row.original.status;
          return (
            <div
              onClick={
                status.toLowerCase() === InferenceState.COMPLETED ? () => openLightbox(cellProps.row.index) : undefined
              }
              className={`text-dark fw-medium ${
                status.toLowerCase() == InferenceState.COMPLETED ? "processed-image" : ""
              }`}
            >
              {/*<img src={eachCellData.url} alt={eachCellData.name} className="rounded avatar"/>*/} {eachCellData.name}
            </div>
          );
        },
      },
      {
        Header: "Created Date",
        Footer: "Created Date",
        accessor: "uploadedDate",
        disableFilters: true,
        filterable: true,
        Cell: (cellProps: any) => {
          const uploadedDate = cellProps.row.original.uploadedDate;
          return <div>{uploadedDate.split(" ")[0]}</div>;
        },
      },
      {
        Header: "Size",
        Footer: "Size",
        accessor: "size",
        disableFilters: true,
        filterable: true,

        Cell: (cellProps: any) => {
          const size = cellProps.row.original.size;
          return <div>{formatBytes(size)}</div>;
        },
      },
      {
        Header: "Status",
        Footer: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: true,
        Cell: (cellProps: any) => {
          const status = cellProps.row.original.status;
          return <div>{capitalize(status)}</div>;
        },
      },
      {
        Header: "Action",
        filterable: false,
        Cell: (cellProps: any) => {
          const eachCellData = cellProps.row.original;
          return (
            <div className="d-flex flex-wrap gap-1">
              {/* <Button
                color="btn btn-success-subtle"
                onClick={() => openLightbox(cellProps.row.index)}
                disabled={eachCellData.status?.toLowerCase() != InferenceState.COMPLETED}
              >
                <i className="uil uil-eye me-2"></i>View
              </Button> */}
              <Button
                color="btn btn-secondary-subtle"
                onClick={() => downloadInferenceImage(eachCellData.url, eachCellData.name)}
                disabled={eachCellData.status?.toLowerCase() != InferenceState.COMPLETED}
              >
                <i className="uil uil-download-alt me-2"></i>Download
              </Button>
              <Button color="btn btn-danger-subtle" onClick={() => onDeleteImageInference(eachCellData)}>
                <i className="uil uil-trash-alt me-2"></i>Delete
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <ReactDataTableWithPagination
        columns={DATA_COLUMNS}
        data={imageInferences}
        activeBackend={activeBackend}
        isPendingUser={pendingUser}
      />
      {/* Lightbox for the image and video viewer */}
      <Lightbox
        slides={imageInferences
          .filter((fileData) => fileData.status.toLowerCase() === InferenceState.COMPLETED)
          .map((fileData) => ({
            src: fileData.url,
            // title: fileData?.name,
            // description: fileData?.name,
            // size: fileData.size,
            // status: fileData.status,
            // uploadedDate: fileData.uploadedDate,
            // download: fileData.status.toLowerCase() === InferenceState.COMPLETED,
          }))}
        open={isOpenLightbox}
        index={fileIndex}
        on={{ view: ({ index: currentIndex }) => setFileIndex(currentIndex) }} //Tracking Slide Index
        close={() => setIsOpenLightbox(false)}
        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
        carousel={{
          finite,
        }}
        plugins={[CustomDownload, ZipDownload, Fullscreen, Thumbnails, Zoom, Counter, Video]}
      />
    </React.Fragment>
  );
};
export default ImageDataTableLightBox;
