// action
import { profileSuccess, profileError } from "./reducer";

import {getFirebaseAuthService} from "../../../utils/firebase_utils";
import FirebaseAuthService from "../../../services/FirebaseAuthService";
import {AuthUserData} from "../../../constants/models/Models";

export const editUserSettingProfile: any = (userData: AuthUserData, selectedPhoto: File) => async (dispatch: any) => {
    try {
        let response;
        const firebaseAuthService: FirebaseAuthService | null = getFirebaseAuthService();
        response = firebaseAuthService!.updateUserSettingProfile(userData, selectedPhoto)


        const data = await response;

        if (data) {
            dispatch(profileSuccess(data))
        }

    } catch (error) {
        dispatch(profileError(error))
    }
};