export enum InferenceType {
    VIDEO = 'Video',
    IMAGE = 'Image',
}
export enum CountryCode {
    AU = 'au',
    UK = 'uk',
    IE = 'ir',
}

export enum CategoryCode {
    FOOD = 'food',
    ALCOHOL = 'alcohol',
    GAMBLING = 'gambling',
    VAPING = 'vape',
}


