import React, {useState} from "react";
import {Button, Col, Row} from "reactstrap";
import {ImageInferenceData} from "../../constants/types";
import FileUploadModal from "../Common/Modals/FileUploadModal";
import {InferenceType} from "../../constants/enums/all_enums";
import {InferenceState} from "../../constants/enums/inference_enums";

interface ImageInferenceDataTableMenuProps {
    activeBackend: boolean;
    modalOpen: boolean;
    onToggleModal: () => void;
    onDownloadImageInferences: () => Promise<void>;
    onDeleteImageInferences: () => void;
    imageInferences: ImageInferenceData[];
    onHandleUploadImages: (files: File[]) => void;
}

const ImageInferenceDataTableActionMenu = ({activeBackend, modalOpen, onToggleModal, onDownloadImageInferences, onDeleteImageInferences, imageInferences, onHandleUploadImages}: ImageInferenceDataTableMenuProps) => {

    return (
        <React.Fragment>
            <Row className="mb-2">
                <Col lg={1} md={1}>
                </Col>

                <Col lg={5} md={5}>
                </Col>

                <Col lg={6} md={6}>
                    <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                        <Button onClick={onDownloadImageInferences} color="btn btn-success-subtle" disabled={!imageInferences?.some(fileData => fileData.status.includes(InferenceState.COMPLETED))}>
                            Download All
                        </Button>
                        <Button onClick={onDeleteImageInferences} color="btn btn-danger-subtle" disabled={imageInferences?.length == 0}>
                            Delete All
                        </Button>
                        <div className="text-sm-end">
                            <Button className="btn btn-primary-subtle" disabled={!activeBackend} onClick={onToggleModal}>Upload Image</Button>
                        </div>
                    </div>
                </Col>
                <FileUploadModal isOpen={modalOpen} fileUploadType={InferenceType.IMAGE} toggleModal={onToggleModal} title="Upload Image" handleUpload={onHandleUploadImages}/>
            </Row>
        </React.Fragment>
    );
};

export default ImageInferenceDataTableActionMenu;