import React, {ChangeEvent, FormEvent, useEffect, useRef, useState} from "react";
import {Container, Row, Col, Form, Button, FormGroup, Input} from "reactstrap";
import {Link} from "react-router-dom";

//import images
import logoDark from "../../assets/images/scanner-logo.png";
import {
    APP_CONFIG_DEFAULT,
    AUTH_FORGOT_PASSWORD_PAGE,
    AUTH_LOGIN_PAGE, AUTH_RESET_PASSWORD_PAGE
} from "../../config/config";
import {
    DEAKIN_NAME,
    DEAKIN_URL,
    IHT_DEAKIN_NAME,
    IHT_DEAKIN_URL,
    IISRI_DEAKIN_NAME,
    IISRI_DEAKIN_URL
} from "../../utils/url_helper";
import {isStrongPassword} from "../../utils/utils";

const ResetPassword = () => {
    document.title = AUTH_FORGOT_PASSWORD_PAGE.label + " | " + APP_CONFIG_DEFAULT.title;

    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const passwordRef = useRef<HTMLInputElement>(null);
    const confirmPasswordRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (passwordRef.current) {
            passwordRef.current.focus();
        }
    }, []);

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        if (error) setError("");
    };

    const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
        if (error) setError("");
    };


    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!isStrongPassword(password)) {
            if (password.length < 6) {
                setError("Password must be at least six characters long.");
            } else {
                setError("Password must be at least six characters and should include a combination of numbers, letters and special character.");
            }
            if (passwordRef.current) {
                passwordRef.current.focus();
            }
            return;
        }

        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            if (confirmPasswordRef.current) {
                confirmPasswordRef.current.focus();
            }
            return;
        }
        // Password reset logic here

    };


    return (
        <React.Fragment>
            <div className="authentication-bg min-vh-100">
                <div className="bg-overlay bg-white"></div>
                <Container>
                    <div className="d-flex flex-column min-vh-100 px-3 pt-4">
                        <Row className=" justify-content-center my-auto">
                            <Col md={8} lg={6} xl={4}>
                                <div className="text-center py-5">
                                    <div className="mb-4 mb-md-5">
                                        <Link to={APP_CONFIG_DEFAULT.path}
                                              className="d-block auth-logo">
                                            <img src={logoDark} alt="" height="22" className="auth-logo-dark"/>
                                        </Link>
                                    </div>
                                    <div className="text-muted mb-4">
                                        <h5 className="">{AUTH_RESET_PASSWORD_PAGE.label}</h5>
                                        <p>Your password must be at least six characters and should include a
                                            combination of numbers, letters and special character.</p>
                                    </div>
                                    <Form onSubmit={handleSubmit}>
                                        <FormGroup>
                                            <div className="form-floating form-floating-custom text-start mb-3">
                                                <Input type="password" className="form-control" id="input-newpassword"
                                                       value={password}
                                                       onChange={handlePasswordChange}
                                                       innerRef={passwordRef}
                                                       placeholder="Enter new password"
                                                />
                                                <label htmlFor="input-newpassword">New password</label>
                                                <div className="form-floating-icon">
                                                    <i className="uil uil-padlock"></i>
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <div className="form-floating form-floating-custom text-start mb-3">
                                                <Input type="password" className="form-control"
                                                       id="input-confirmpassword"
                                                       value={confirmPassword}
                                                       onChange={handleConfirmPasswordChange}
                                                       innerRef={confirmPasswordRef}
                                                       placeholder="Confirm new password"
                                                />
                                                <label htmlFor="input-confirmpassword">Confirm password</label>
                                                <div className="form-floating-icon">
                                                    <i className="uil uil-check-circle"></i>
                                                </div>
                                            </div>
                                        </FormGroup>

                                        {error && <div className="text-danger mb-3">{error}</div>}

                                        <div className="mt-3">
                                            <Button color="info" type="submit" className="w-100">Reset Password</Button>
                                        </div>
                                    </Form>

                                    <div className="mt-5 text-center text-muted">
                                        <p>Remember It ?
                                            <Link to={AUTH_LOGIN_PAGE.path}
                                                  className="fw-medium text-decoration-underline">
                                                {" "}
                                                {AUTH_LOGIN_PAGE.label}{" "}
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12}>
                                <div className="text-center text-muted p-4">
                                    <p className="mb-0">
                                        &copy; {new Date().getFullYear()} {APP_CONFIG_DEFAULT.title}.
                                        Developed by <Link to={IISRI_DEAKIN_URL} target="_blank" rel="noreferrer" className="text-reset">{IISRI_DEAKIN_NAME}</Link>
                                        {" "} and <Link to={IHT_DEAKIN_URL} target="_blank" rel="noreferrer" className="text-reset">{IHT_DEAKIN_NAME}</Link>,
                                        {" "}<Link to={DEAKIN_URL} target="_blank" rel="noreferrer" className="text-reset">{DEAKIN_NAME}</Link>
                                    </p>
                                </div>
                            </Col>
                        </Row>

                    </div>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default ResetPassword;