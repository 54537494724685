import {CSVHeader, ProcessedInference} from "../constants/types";
import {RoleType, UserStatus} from "../constants/enums/Auth";


/*** LeftSidebar menu icon and default menu set */
export const tToggle = (isHeaderMenu: boolean) => {
    if (isHeaderMenu && (window.screen.width <= 998 || window.innerWidth <= 998)) {
        document.body.classList.toggle("sidebar-enable");
    }
    // set data-sidebar-size
    isHeaderMenu ? document.body.setAttribute("data-sidebar-size", "lg") : document.body.setAttribute("data-sidebar-size", "sm")
}

export const getUserRoleType = (role: string): RoleType => {
    switch (role.toLowerCase()) {
        case 'user':
            return RoleType.USER;
        case 'admin':
            return RoleType.ADMIN_USER;
        case 'super admin':
            return RoleType.SUPER_ADMIN_USER;
        default:
            return RoleType.USER;
    }
}

const readUploadedFileAsDataURLAsync = (inputFile: File): Promise<string> => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
        temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new DOMException("Problem parsing input file."));
        };

        temporaryFileReader.onload = () => {
            resolve(temporaryFileReader.result as string);
        };
        temporaryFileReader.readAsDataURL(inputFile);
    });
};

export const convertToBase64 = async (file: File) => {
    try {
        return await readUploadedFileAsDataURLAsync(file);
    } catch (e) {
        console.warn((e as Error).message);
    }
};

export const blobToFile = (blob: Blob, fileName: string):File => {
    const file = new File([blob], fileName, { type: blob.type });
    return file;
};

export const isValidEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};

export const isStrongPassword = (password: string): boolean => {
    // Password must be at least six characters and should include a combination of numbers, letters and special character
    const strongRegex = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*\W)[A-Za-z\d\W]{6,}$/; // /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)[A-Za-z\d\W]{6,}$/;
    return strongRegex.test(password);
};

export const formattedCurrentDateTimeStamp = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Month is zero-indexed
    const day = ('0' + currentDate.getDate()).slice(-2);
    const hours = ('0' + currentDate.getHours()).slice(-2);
    const minutes = ('0' + currentDate.getMinutes()).slice(-2);
    const seconds = ('0' + currentDate.getSeconds()).slice(-2);
    const milliseconds = ('00' + currentDate.getMilliseconds()).slice(-3);

    const formattedDate = `${year}-${month}-${day}-${hours}-${minutes}-${seconds}-${milliseconds}`;
    return formattedDate;
}

export const formattedCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Month is zero-indexed
    const day = ('0' + currentDate.getDate()).slice(-2);
    const formattedDate = `${day}${month}${year}`;
    return formattedDate;
}

export const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const formatDate = (date: Date) => {
    if (!date) return "";
    return new Date(date).toLocaleString().replaceAll(',', '');
}

export const formatEpochTimestampDate = (unixEpochTimestamp: number) => {
    if (!unixEpochTimestamp) return "";
    return new Date(unixEpochTimestamp * 1000).toLocaleString().replaceAll(',', '');
}

export const getFileTypeFromBase64 = (base64String: string) => {
    // Extract the data URI prefix (e.g., "data:image/png;base64,")
    const prefix = base64String.substring(0, base64String.indexOf(';base64,'));

    // Extract the file extension from the prefix (e.g., "image/png")
    return prefix.substring(prefix.indexOf('/') + 1);
}

export const getFileNameFromUrl = (url: string) => {
    const parts = url.split('/');
    return parts[parts.length - 1];
};


export const capitalize = (word: string) => {
    if (!word) return "";
    return word.charAt(0).toUpperCase() + word.slice(1);
};


export const capitalizeWords = (words: string) => {
    if (!words) return "";
    return words
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};

export const getMIMETypeFromBase64 = (base64String: string) => {
    // Extract the data URI prefix (e.g., "data:image/png;base64,")
    const prefix = base64String.substring(0, base64String.indexOf(';base64,'));

    // Extract the MIME type from the prefix (e.g., "image/png")
    return prefix.substring(prefix.indexOf(':') + 1);
}
const convertToCSV = (data: any[], headers: CSVHeader[], separator: string = ','): string => {
    if (!data || data.length === 0) {
        return '';
    }
    // Create the header row
    const headerRow = headers.map(header => header.label);

    // Create the columns codes
    const columns = headers.map(header => header.code);
    const csv = [
        headerRow.join(separator), // header row first
        ...data.map(row => columns.map(key => row[key]).join(separator))
    ].join('\r\n');

    return csv;
};

export const exportCSV = async (jsonData: any[], headers: CSVHeader[], fileName: string = 'video-summary') => {
    const csvData = convertToCSV(jsonData, headers, ',');
    const uFEFF = "\uFEFF";  // Byte Order Mark for UTF-8
    const blob = new Blob([uFEFF, csvData], { type: 'text/csv;charset=utf-8;' });
    triggerFileDownloadProcess(blob, `${fileName}.csv`);
};

interface DetectedObject {
    bbox: [number, number, number, number]; // [x, y, width, height]
    class: string;
    score: number;
}

export const drawRect = (detectedObjects: any[], ctx: CanvasRenderingContext2D) => {
    // Loop through each prediction

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const colors: { [key: string]: string } = {
        'person': 'red',
        'car': 'green',
        // Add more classes and colors as needed
    };

    detectedObjects.forEach(prediction => {
        const [x, y, width, height] = prediction.bbox;
        const label = `${prediction.class} (${Math.round(prediction.score * 100)}%)`;
        const color = colors[prediction.class] || 'yellow'; // Default color

        // Draw bounding box
        ctx.strokeStyle = color;
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.rect(x, y, width, height);
        ctx.stroke();

        // Draw label
        ctx.fillStyle = color;
        ctx.font = '16px Arial';
        ctx.fillText(label, x, y - 5);
    });
};

export const drawInferenceBBoxCanvasImage = (video: HTMLVideoElement, ctx: CanvasRenderingContext2D, detectedInference: ProcessedInference) => {
    // ctx!.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    // ctx.drawImage(video, 0, 0);

    const colors: { [key: string]: string } = {
        'person': 'red',
        'car': 'green',
        // Add more classes and colors as needed
    };

    if (detectedInference && detectedInference.scanner && detectedInference.scanner.instances) {
        const detectedInstances:any[] = detectedInference.scanner.instances;

        detectedInstances.forEach(instance => {
            const { brand, score, bbox } = instance;
            const [x1, y1, x2, y2] = bbox;
            const bboxWidth = x2 - x1;
            const bboxHeight = y2 - y1;

            const label = `${brand} (${Math.round(score * 100)}%)`;
            const color = instance.class in colors ? colors[instance.class] : '#90EE90'; // Default color if class not found

            // // Draw bounding box
            // ctx!.strokeStyle = color;
            // ctx!.lineWidth = 2;
            // ctx!.beginPath();
            // ctx!.rect(x1, y1, bboxWidth, bboxHeight);
            // ctx!.stroke();
            //
            // // Draw label
            // ctx!.fillStyle = color;
            // ctx!.font = '16px Arial';
            // ctx!.fillText(label, x1 + 4, y1 + 20); // Adjust label position

            ctx!.strokeStyle = "#f922ee";
            ctx!.lineWidth = 2;
            ctx!.strokeRect(x1, y1, bboxWidth, bboxHeight);
            ctx!.fillStyle = "#000000";
            ctx!.fillRect(x1, y1 - 8, bboxWidth, 16);
            ctx!.fillStyle = "#ffffff";
            ctx!.font = "16px Arial";
            ctx!.fillText(label, x1+4, y1+5); // Draw label
        });
    }
};
export const drawInferenceCanvasImage = (video: HTMLVideoElement, ctx: CanvasRenderingContext2D, detectedInference: ProcessedInference) => {


    const image = new Image();
    image.onload = () => {
        ctx.drawImage(image, 0, 0);
    };

    // Set the image source to the base64 string
    image.src = detectedInference!.image!.base64;

};


export const drawVideoCanvasImage = (video: HTMLVideoElement, ctx: CanvasRenderingContext2D, detectedObjects: DetectedObject[]) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.drawImage(video, 0, 0);

    const colors: { [key: string]: string } = {
        'person': 'red',
        'car': 'green',
        // Add more classes and colors as needed
    };

    detectedObjects.forEach(prediction => {
        const [x, y, width, height] = prediction.bbox;
        const label = `${prediction.class} (${Math.round(prediction.score * 100)}%)`;
        const color = colors[prediction.class] || 'yellow'; // Default color

        // Draw bounding box
        ctx.strokeStyle = color;
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.rect(x, y, width, height);
        ctx.stroke();

        // Draw label
        ctx.fillStyle = color;
        ctx.font = '16px Arial';
        ctx.fillText(label, x, y - 5);
    });
};
export const drawVideoCanvasImage2 = (video: HTMLVideoElement, ctx: CanvasRenderingContext2D, detectedObjects:any) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.drawImage(video, 0, 0);

    const colors: { [key: string]: string } = {
        'person': 'red',
        'car': 'green',
        // Add more classes and colors as needed
    };

    for (let prediction of detectedObjects) {
        const [x, y, width, height] = prediction.bbox;
        const label = `${prediction.class} (${Math.round(prediction.score * 100)}%)`;
        const color = colors[prediction.class] || 'yellow'; // Default color

        // Draw bounding box
        ctx.strokeStyle = color;
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.rect(x, y, width, height);
        ctx.stroke();

        // Draw label
        ctx.fillStyle = color;
        ctx.font = '16px Arial';
        ctx.fillText(label, x, y - 5);
    }
};

export const drawVideoRect = (detectedObjects: DetectedObject[], ctx: CanvasRenderingContext2D) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const colors: { [key: string]: string } = {
        'person': 'red',
        'car': 'green',
        // Add more classes and colors as needed
    };

    detectedObjects.forEach(prediction => {
        const [x, y, width, height] = prediction.bbox;
        const label = `${prediction.class} (${Math.round(prediction.score * 100)}%)`;
        const color = colors[prediction.class] || 'yellow'; // Default color

        // Draw bounding box
        ctx.strokeStyle = color;
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.rect(x, y, width, height);
        ctx.stroke();

        // Draw label
        ctx.fillStyle = color;
        ctx.font = '16px Arial';
        ctx.fillText(label, x, y - 5);
    });
};

export const jsonToHeaderQuery = (jsonParams: Record<string, string | number>) => {
    return Object.entries(jsonParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
};

export const getStatusBadgeCssClass = (status: string) => {
    if (status?.toLowerCase() === UserStatus.ACTIVE.toLowerCase()) {
        return "badge bg-success";
    } else if (status?.toLowerCase() === UserStatus.PENDING.toLowerCase()) {
        return "badge bg-warning";
    // } else if (status?.toLowerCase() === UserStatus.DISABLED.toLowerCase()) {
    //     return "badge bg-secondary";
    } else if (status?.toLowerCase() === UserStatus.SUSPENDED.toLowerCase()) {
        return "badge bg-danger";
    } else {
        return "badge bg-info";
    }
}

/**
 * Triggers download of the Blob.
 * @param {Blob} blob - The Blob to download.
 * @param {string} fileName - The name to give the downloaded file.
 */
export const triggerFileDownloadProcess = (blob: Blob, fileName: string): void => {
    const downloadLink = document.createElement('a');
    const downloadUrl = URL.createObjectURL(blob);
    downloadLink.href = downloadUrl;
    downloadLink.download = fileName;

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(downloadUrl); // Free up memory
};


