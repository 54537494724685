import {FileUploadCount, VideoFileProjectData} from "./types";
import {InferenceState} from "./enums/inference_enums";

export const CHUNK_FILE_SIZE_MB = 5;
export const DROPZONE_MAX_VIDEO_MB_SIZE = 1024;
export const DROPZONE_MAX_VIDEO_FILES = 1;
export const DROPZONE_MAX_IMAGE_FILES = 10;
export const DROPZONE_MULTIPLE_VIDEO_FILES = false;
export const DROPZONE_MULTIPLE_IMAGE_FILES = true;

export const PROJECT_NAME_REGEX = /^[a-zA-Z0-9 _-]+$/;

export const DROPZONE_IMAGE_FORMATS = {
    "image/jpeg": [".jpeg", ".JPEG"],
    "image/jpg": [".jpg", ".JPG"],
    "image/png": [".png", ".PNG"],
    "image/webp": [".webp", ".WEBP"],
};

export const DROPZONE_VIDEO_FORMATS = {
    "video/mp4": [".mp4", ".MP4"],
    "video/mov": [".mov", ".MOV"]
};

export const DROPZONE_ALL_FORMATS = {
    "image/jpeg": [".jpeg", ".JPEG"],
    "image/png": [".png", ".PNG"],
    "video/mp4": [".mp4", ".MP4"],
    "video/mov": [".mov", ".MOV"]
};

export const INITIAL_VIDEO_FILE_PROJECT_DATA: VideoFileProjectData = {
    project: '',
    uploadFileData: []
}

export const INITIAL_VIDEO_FILE_UPLOAD_COUNT: FileUploadCount = {
    status: InferenceState.LOADED,
    fileNumber: 0,
    totalCount: 0
}