import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {Dropdown, DropdownToggle, DropdownMenu, Row, Col, Badge} from "reactstrap";
import SimpleBar from "simplebar-react";

//import withRouter
import withRouter from "./withRouter";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

import {APP_INFERENCE_PAGE} from "../config/config";
import {ProcessedVideoStateData} from "../constants/types";
import FetchService from "../services/FetchService";
import AuthUserService from "../services/AuthUserService";
import {AuthUserData} from "../constants/models/Models";
import {InferenceState} from "../constants/enums/inference_enums";
import {AxiosError} from "axios";

const NotificationDropdown = () => {
  const toggleMenu = () => setMenu(!menu);

  const [videoInferences, setVideoInferences] = useState<ProcessedVideoStateData[]>([]);
  const [totalCompletedInferences, setTotalCompletedInferences] = useState<number>(0);
  const [totalProcessingInferences, setTotalProcessingInferences] = useState<number>(0);
  const [menu, setMenu] = useState(false);

  const authUser: AuthUserData = AuthUserService.getLoggedAuthorizedUser();

  useEffect(() => {
    fetchData(authUser?.uid).then(); // Initial fetch

    const intervalId = setInterval(() => {
      const hasNotCompleted = videoInferences.some(item => item.state !== InferenceState.COMPLETED);
      if (hasNotCompleted) {
        fetchData(authUser?.uid).then();
      }
    }, 25000); // Fetch data every 15000 milliseconds (15 seconds)

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [videoInferences.some(item => item.state !== InferenceState.COMPLETED)]);


  const fetchData = async(userId: string) => {
    try {
      const response = await FetchService.getVideoInferenceByUserId(userId);
      if (response && response.status===200) {
        const data = response.data;
        if (data instanceof Array) {
          const sortedData = data.sort((a, b) => b.time_created - a.time_created); // sorted in descending order
          setVideoInferences(sortedData);
          const completedData = sortedData.filter(item => item.state === InferenceState.COMPLETED);
          const processingData = sortedData.filter(item => item.state.toLowerCase() === InferenceState.PROCESSING);
          setTotalCompletedInferences(completedData.length);
          setTotalProcessingInferences(processingData.length);
        } else {
          setVideoInferences([]);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status === 401 || error.code === 'ERR_NETWORK') {
        }
      }
    }
  };


  return (
      <React.Fragment>
        <div className={`${totalCompletedInferences > 0 ? 'notification-dropdown' : ''}`}>
          <Dropdown isOpen={menu} toggle={toggleMenu} className="dropdown d-inline-block" tag="li">
            <DropdownToggle className="btn header-item noti-icon" tag="button">
              <Icon name="bell" className="icon-sm"/>
              {totalCompletedInferences > 0 &&
                  <Badge color="danger" className="top-0 start-0 translate-middle">{totalCompletedInferences}</Badge>
              }
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0" dir="left">
              <div className="p-3">
                <Row className="align-items-center">
                  <Col>
                    <h5 className="m-0 font-size-15"> {"Notifications"} </h5>
                  </Col>
                  {/*<div className="col-auto">
                <Link to="#" className="small">
                  {" "}
                  View All
                </Link>
              </div>*/}
                </Row>
              </div>

              <SimpleBar style={{height: "230px"}}>
                <Link to={APP_INFERENCE_PAGE.path} onClick={toggleMenu}
                      className="text-reset notification-item">
                  <div className="d-flex border-bottom align-items-start">
                    <div className="flex-shrink-0">
                      <div className="avatar-sm me-3">
                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                      <i className="uil-check-circle"></i>
                    </span>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      {totalCompletedInferences > 0 &&
                          <h6 className="mb-1">{totalCompletedInferences} completed video inferences</h6>
                      }
                      {totalCompletedInferences === 0 &&
                          <h6 className="mb-1">No inferences completed</h6>
                      }
                      <div className="text-muted">
                        {/*<p className="mb-1 font-size-13">Details about processing</p>*/}
                        {/*<p className="mb-0 font-size-10 text-uppercase fw-bold"><i className="mdi mdi-clock-outline"></i> 5 hour ago</p>*/}
                        <span className="badge badge-success-subtle ms-auto">New</span>
                      </div>
                    </div>
                  </div>
                </Link>

                {/*<h6 className="dropdown-header bg-light">Earlier</h6>*/}
                <h6 className="dropdown-header bg-light">Processing</h6>
                <Link to={APP_INFERENCE_PAGE.path} onClick={toggleMenu} className="text-reset notification-item">
                  <div className="d-flex border-bottom align-items-start">
                    <div className="flex-shrink-0">
                      <div className="avatar-sm me-3">
                    <span className="avatar-title bg-soft-success text-success rounded-circle font-size-16">
                      <i className="uil-process"></i>
                    </span>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      {totalProcessingInferences > 0 &&
                          <h6 className="mb-1">{totalProcessingInferences} processing video inferences</h6>
                      }
                      {totalProcessingInferences === 0 &&
                          <h6 className="mb-1">No processing video inference</h6>
                      }
                      {/*<div className="text-muted">
                    <p className="mb-1 font-size-13">About video inference...</p>
                    <p className="mb-0 font-size-10 text-uppercase fw-bold"><i className="mdi mdi-clock-outline"></i> 1 day ago</p>
                  </div>*/}
                    </div>
                  </div>
                </Link>
              </SimpleBar>
              {/*<div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 btn-block text-center" to="#">
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              {"View all"}{" "}
            </Link>
          </div>*/}
            </DropdownMenu>
          </Dropdown>
        </div>

      </React.Fragment>
  );
};

export default withRouter(NotificationDropdown);