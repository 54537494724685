import React from "react";
import {
    addToolbarButton,
    createIcon,
    IconButton,
    useLightboxState
} from "yet-another-react-lightbox";
import {downloadAllInferenceImages} from "../../utils/image_inference_utils";
import {InferenceState} from "../../constants/enums/inference_enums";
const PLUGIN_DOWNLOAD_ALL = "zip-download";


// Create a custom icon for plugin
const DownloadZipIcon = createIcon("DownloadZipIcon", React.createElement("path", { d: "M20,6h-8l-2-2H4C2.9,4,2.01,4.9,2.01,6L2,18c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8C22,6.9,21.1,6,20,6z M16,16h2v-2h-2v-2 h2v-2h-2V8h4v10h-4V16z M16,16h-2v2H4V6h5.17l2,2H14v2h2v2h-2v2h2V16z" }));

// Define your custom plugin component
function DownloadAllButton() {
    const { slides} = useLightboxState();

    const downloadZipFile = async () => {
        let allImageInferenceData= [];
        slides.forEach((eachSlide) => {
            const eachImageInferenceData = {
                url: eachSlide.src,
                name: eachSlide?.title ? eachSlide.title : eachSlide.description,
                size: eachSlide?.size ? eachSlide.size : 0,
                uploadedDate: eachSlide?.uploadedDate ? eachSlide.uploadedDate : '',
                status: eachSlide?.status ? eachSlide.status: InferenceState.COMPLETED
            }
            allImageInferenceData.push(eachImageInferenceData);
        })
        await downloadAllInferenceImages(allImageInferenceData);
    }

    return (
        <IconButton label="Download Zip" icon={DownloadZipIcon} onClick={downloadZipFile} disabled={slides.length<=1}/>
    );
}

export function ZipDownload({ augment }) {
    augment(({ toolbar, ...restProps }) => ({
        toolbar: addToolbarButton(toolbar, PLUGIN_DOWNLOAD_ALL, <DownloadAllButton />),
        ...restProps,
    }));
}

