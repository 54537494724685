import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

interface BreadcrumbItem {
  label: string;
  path: string;
  title?: string
}
interface BreadcrumbProps {
  title: BreadcrumbItem;
  breadcrumbItem: BreadcrumbItem;
  subBreadcrumbItem?: BreadcrumbItem;
}

const Breadcrumb = ({title, breadcrumbItem, subBreadcrumbItem }: BreadcrumbProps) => {

  return (
      <Row>
        <Col xl={12} lg={12} xs={12}>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">{subBreadcrumbItem ? subBreadcrumbItem.title ? subBreadcrumbItem.title : subBreadcrumbItem.label : breadcrumbItem.label}</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item"><Link to={title.path}>{title.label}</Link></li>
                {!subBreadcrumbItem &&
                    <li className={"breadcrumb-item active"}>{breadcrumbItem.label}</li>
                }

                {subBreadcrumbItem &&
                    <li className={"breadcrumb-item"}><Link to={breadcrumbItem.path}>{breadcrumbItem.label}</Link></li>
                }
                {subBreadcrumbItem &&
                    <li className="breadcrumb-item active">{subBreadcrumbItem?.label}</li>
                }
              </ol>
            </div>
          </div>
        </Col>
      </Row>
  );
};

export default Breadcrumb;