import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";

import {configureStore} from "@reduxjs/toolkit";
import rootReducer from "./slices";

import reportWebVitals from './reportWebVitals';


const store = configureStore({reducer: rootReducer});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <React.Fragment>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </React.Fragment>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
