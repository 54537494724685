import {FirebaseOptions} from "firebase/app";
import {CategoryCode, CountryCode} from "../enums/all_enums";
import {RoleType} from "../enums/Auth";
import CreateVideoProjectModal from "../../pages/Common/Modals/CreateVideoProjectModal";
import {InferenceState} from "../enums/inference_enums";

export interface GoogleConfigProps {
    API_KEY?: string;
    CLIENT_ID?: string;
    SECRET?: string;
}

export interface FacebookConfigProps {
    APP_ID?: string;
}

export interface ApplicationAuthConfigProps {
    firebase:FirebaseOptions;
    google?:GoogleConfigProps;
    facebook?:FacebookConfigProps;
}

export interface UrlConfig {
    apiBaseUrl: string;
}
interface SubMenuItem{
    id: number;
    label: string;
    path: string;
    parentId: number;
}

export interface MenuItem {
    id: number;
    name: string;
    icon: string;
    path: string;
    subItems?: SubMenuItem[];
}
export interface UploadVideoFileData {
    file: File;
    fileNumber: number;
    status: InferenceState;
    uploadPercentage: number;
}

export interface VideoFileProjectData {
    project: string;
    uploadFileData: UploadVideoFileData[] | any
}

export interface FileUploadCount {
    status: InferenceState;
    fileNumber: number;
    totalCount: number;
}

export interface ProcessVideoData {
    json: string;
    video: string;
    video_full?: string;
}

export interface ProcessedVideoStateData {
    progress?: number;
    project?: any;
    size?: any;
    time_created?: any;
    state: string;
    urls: ProcessVideoData;
    name?: string;
}

export interface VideoInferenceProject {
    project?: string;
    projectData?: ProcessedVideoStateData[];
    [InferenceState.COMPLETED]: number;
    [InferenceState.PROCESSING]: number;
    [InferenceState.PENDING]: number;
    [InferenceState.FAILED]: number;
    time_created?: any;
    total?: number;
}

export interface ImageInferenceData {
    url: any;
    size: any;
    uploadedDate: any;
    status: string;
    name: string;
}

export interface CategoryProps {
    label: string;
    value: CategoryCode;
}

export interface CountryProps {
    label:string;
    value: CountryCode;
}

export interface CountryCategories {
    [key: string]: CategoryProps[];
}

export interface RoleProps {
    label: string;
    value: RoleType;
}

export interface ScannerParameters {
    country?: CountryProps;
    category?: CategoryProps[];
}

export interface FileUploadCheckParams {
    label:string;
    value:string;
    checked?:boolean;
}

export interface FilesProps {
    selectedFiles: File[];
}

export interface ProcessedInference {
    image?: any;
    merged?: any;
    ocr?: any;
    scanner?: any;
}

export interface ImageFileData {
    frame: string;
    name: string;
    size: number;
    sizeText: string;
    type: string;
    base64: string;
    status: string;
    uploadedDate: Date;
    dimensions?: Array<number>;
    duration?: number;
    inference?: ProcessedInference;
}



export interface EndpointProps {
    name: string;
    endPoint: string;
}

export interface FrameMetaData {
    time_code: string;
    base64: string;
}


export interface VideoDetails {
    name: string;
    type: string;
    frame_count: number;
    fps: number;
}


export interface BoundingBoxInstance {
    bbox: [number, number, number, number];
    brand: string;
    score: number;
    market: string;
    category: string;
    regions: string[];
}

export interface MergedBoundingBoxInstance {
    instances?: BoundingBoxInstance[];
}

export interface InferenceBoundingBoxInstance {
    merged?: MergedBoundingBoxInstance;
}


export interface DetectedFrame {
    frame: number;
    time_code: string;
    inference?: InferenceBoundingBoxInstance;
}

export interface AppModel {
    name: string;
    version: any;
}


export interface VideoObjectDetectionJsonData {
    app: AppModel;
    markets?: any[];
    video_details: VideoDetails;
    results: DetectedFrame[];
}

export interface CSVHeader {
    label: string;
    code: string;
}
export interface CSVDataProps {
    user: string;
    videoName: string;
    frame: number;
    frameGap: number;
    startTimeCode: string;
    endTimeCode: string;
    adNumber: number;
    market: string;
    category: string;
    brandName: string;
    adNumberBrandName: string;
    brandCount: number;
    totalBrandCount: any;
    regions: any;
}

export const csvHeaders: CSVHeader[] = [
    { label: "User", code: "user" },
    { label: "Video name", code: "videoName" },
    // { label: "Frame", code: "frame" },
    // { label: "Frame gap", code: "frameGap" },
    { label: "Start time", code: "startTimeCode" },
    { label: "End time", code: "endTimeCode" },
    { label: "Ad number", code: "adNumber" },
    { label: "Model", code: "market" },
    { label: "Category", code: "category" },
    { label: "Brand name", code: "brandName" },
    { label: "Brand count", code: "brandCount" },
    // { label: "Total Brand Count", code: "totalBrandCount" },
];

export interface ScannerParametersTest {
    country: string;
    category: string;
    periodFrom: string;
    periodTo: string;
}

export interface ReactDataTableColumnProps {
    Header: string;
    Footer?: string;
    accessor?: string;
    disableFilters?: boolean;
    filterable?: boolean;
    Filter?: any;
    Cell?: any;
}