import { useEffect, useState } from "react";
import { AuthUserData } from "src/constants/models/Models";
import { FileUploadCount, UploadVideoFileData, VideoFileProjectData, VideoInferenceProject } from "src/constants/types";
import AuthUserService from "src/services/AuthUserService";
import FetchService from "src/services/FetchService";
import { Card, CardText, Row, Col, Button, CardGroup } from "reactstrap";
import { useNavigate } from "react-router-dom";
import ProjectCard from "./ProjectCard";
import CreateProject from "./CreateProject";
import { UserStatus } from "src/constants/enums/Auth";
import { getUserXApiKey, headerContentType } from "src/utils/auth-token-header";
import { InferenceState } from "src/constants/enums/inference_enums";
import { INITIAL_VIDEO_FILE_PROJECT_DATA, INITIAL_VIDEO_FILE_UPLOAD_COUNT } from "src/constants/constants";
import { notifyError, notifySuccess } from "src/services/NotificationService";
import { AxiosError, AxiosProgressEvent } from "axios";

export default function ProjectsCards() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const authUser: AuthUserData = AuthUserService.getLoggedAuthorizedUser();
  const [videoProject, setVideoFileProject] = useState<VideoFileProjectData>(INITIAL_VIDEO_FILE_PROJECT_DATA);
  const [fileUploadCount, setFileUploadCount] = useState<FileUploadCount>(INITIAL_VIDEO_FILE_UPLOAD_COUNT);
  let navigate = useNavigate();

  const [activeBackend, setActiveBackend] = useState<boolean>(
    authUser.status?.toLowerCase() === UserStatus.ACTIVE.toLowerCase() &&
      (authUser?.accessXApiKey !== "" || authUser.accessXApiKey !== undefined)
  );

  const [projects, setProjects] = useState<any[]>([]);

  useEffect(() => {
    const authUser: AuthUserData = AuthUserService.getLoggedAuthorizedUser();
    FetchService.getVideoInferenceByUserId(authUser?.uid).then((response) => {
      const data = response.data as VideoInferenceProject[] 
      const projectsNames = [...new Set(data.map(p => p.project))]
      setProjects(projectsNames);
    });
  }, []);
  const handleToggleModal = () => setIsModalOpen(!isModalOpen);
  //
  const uploadVideo = async (projectName: string, fileData: UploadVideoFileData) => {
    const eachFormData = new FormData();
    eachFormData.append("user", JSON.stringify({ id: authUser?.uid }));
    eachFormData.append(fileData.file.name, fileData.file);
    if (projectName) {
      eachFormData.append("project", projectName);
    }
    const updateVideoProjectPercentageStatus = (
      fileData: UploadVideoFileData,
      percentage: number,
      status: InferenceState
    ) => {
      setVideoFileProject((prevState) => ({
        ...prevState,
        uploadFileData: prevState.uploadFileData.map((currentFile: UploadVideoFileData) =>
          currentFile.file.name === fileData.file.name
            ? { ...currentFile, uploadPercentage: percentage, status: status }
            : currentFile
        ),
      }));

      setFileUploadCount((prevState) => ({
        ...prevState,
        status: fileData.fileNumber === fileUploadCount.totalCount ? status : InferenceState.UPLOADING,
        fileNumber: fileData.fileNumber,
      }));
    };

    const response = await FetchService.uploadVideo(eachFormData, {
      headers: {
        "Content-Type": headerContentType.FORM_DATA_TYPE,
        "X-API-KEY": getUserXApiKey(),
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total!);
        updateVideoProjectPercentageStatus(fileData, percentCompleted, InferenceState.UPLOADING);
      },
    });

    if (response && response.status === 200) {
      updateVideoProjectPercentageStatus(fileData, 100, InferenceState.UPLOADED);
    } else {
      console.error("Response is not a Blob:", response);
      notifyError(`${fileData.file.name} video upload failed. Please try again.`);
    }
  };
  const handleVideoUpload = async (videoProjectData: VideoFileProjectData) => {
    try {
      for (const fileData of videoProjectData.uploadFileData) {
        await uploadVideo(videoProjectData.project, fileData);
      }
      handleToggleModal();
      if (fileUploadCount.totalCount === 1) {
        notifySuccess(`Video has been uploaded successfully.`);
      } else {
        notifySuccess(`All ${fileUploadCount.totalCount} videos have been uploaded successfully.`);
      }
      const path = "/inference";
      navigate(path, { state: { projectName: videoProjectData.project } });
      // notifySuccess("All Videos have been uploaded successfully.");
    } catch (error) {
      console.error("Error uploading files:", error);
      notifyError("An unexpected error occurred during the video upload.");
    }
  };

  return (
    <div style={{ margin: "100px 20px " }}>
      <CreateProject
        activeBackend={activeBackend}
        modalOpen={isModalOpen}
        onToggleModal={handleToggleModal}
        onHandleUploadVideo={handleVideoUpload}
        videoProjectData={videoProject}
        setVideoProject={setVideoFileProject}
        fileUploadCount={fileUploadCount}
        setFileUploadCount={setFileUploadCount}
      />
      <h2 style={{ margin: "0 30px 30px 30px" }}>Projects</h2>
      <CardGroup>
        {projects.map((p, index) => (
          <ProjectCard key={index} projectName={p} />
        ))}
      </CardGroup>
    </div>
  );
}
