export enum InferenceState {
    LOADED = 'loaded',
    SELECTED='selected',
    UPLOADING= 'uploading',
    UPLOADED= 'uploaded',
    PROCESSING= 'processing',
    PENDING= 'pending',
    COMPLETED= 'completed',
    PAUSED= 'paused',
    CANCELLED= 'cancelled',
    FAILED= 'failed',
}

export enum AspectRatioType {
    RATIO_16x9 = '16x9',
    RATIO_9x16 = '9x16',
    RATIO_21x9 = '21x9',
    RATIO_9x19 = '9x19',
    RATIO_19x9 = '19x9',
    RATIO_4x3 = '4x3',
    RATIO_1x1 = '1x1'
}

export enum FirebaseAuthErrorCode {
    AUTH_USER_NOT_FOUND = "auth/user-not-found",
    AUTH_PERMISSION_DENIED = "permission-denied",
    AUTH_WRONG_PASSWORD = "auth/wrong-password",
    AUTH_INVALID_EMAIL = "auth/invalid-email",
    AUTH_EMAIL_ALREADY_IN_USE = "auth/email-already-in-use",
    AUTH_INVALID_CREDENTIAL = "auth/invalid-credential",
    AUTH_WEAK_PASSWORD = "auth/weak-password",
    AUTH_OPERATION_NOT_ALLOWED = "auth/operation-not-allowed",
    AUTH_ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL = "auth/account-exists-with-different-credential",
    AUTH_NETWORK_REQUEST_FAILED = "auth/network-request-failed",
}

export enum ResponseType {
    BLOB = "blob"
}
