import React, {useEffect, useState} from "react";

import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {ImageInferenceData} from "../../constants/types";
import FetchService from "../../services/FetchService";
import { APP_CONFIG_DEFAULT, APP_IMAGE_INFERENCE_PAGE } from "../../config/config";
import {AxiosError} from "axios";
import ImageInferenceDataTableActionMenu from "./ImageInferenceDataTableActionMenu";
import JSZip from "jszip";
import {
    capitalize,
    formatEpochTimestampDate,
    formattedCurrentDate,
    getFileNameFromUrl
} from "../../utils/utils";
import ImageDataTableLightBox from "./ImageDataTableLightBox";
import {DeleteModal} from "../Common/Modals/DeleteModal";
import {notifyError, notifySuccess} from "../../services/NotificationService";
import Breadcrumb from "../../common/Breadcrumb";
import {AuthUserData} from "../../constants/models/Models";
import AuthUserService from "../../services/AuthUserService";
import {InferenceState} from "../../constants/enums/inference_enums";
import {UserStatus} from "../../constants/enums/Auth";
import {downloadAllInferenceImages} from "../../utils/image_inference_utils";

const ImageInference = () => {
    document.title = APP_IMAGE_INFERENCE_PAGE.label + " | " + APP_CONFIG_DEFAULT.title;

    const [deleteInferencesModal, setDeleteInferencesModal] = useState<boolean>(false);
    const [deleteInferenceModal, setDeleteInferenceModal] = useState<boolean>(false);
    const [selectedImageInferenceData, setSelectedImageInferenceData] = useState<ImageInferenceData | null>(null);
    const [activeBackendMessage, setActiveBackendMessage] = useState('');

    const authUser: AuthUserData = AuthUserService.getLoggedAuthorizedUser();
    const [isLoading, setIsLoading] = useState(true);
    const [imageInferences, setImageInferences] = useState<ImageInferenceData[]>([]);
    const [activeBackend, setActiveBackend] = useState<boolean>(authUser.status?.toLowerCase() === UserStatus.ACTIVE.toLowerCase() && (authUser?.accessXApiKey !== "" || authUser.accessXApiKey !== undefined));

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        try {
            // localStorage.setItem(imageLocalStorage, JSON.stringify(imageInferences));
            loadData(authUser?.uid).then(); // Initial fetch

        } catch (error: any) {
            if (error.code === 22) {
                console.error('LocalStorage quota exceeded. Unable to store data.');
                // Notify the user or implement another strategy to handle the error.
            } else {
                console.error('Error storing data in LocalStorage:', error);
            }
        }

    },[]);

    const handleToggleModal = () => setIsModalOpen(!isModalOpen);

    const loadData = async(userId: string) => {
        try {
            const response = await FetchService.getImageBrandsByUserId(userId);
            setActiveBackend(true);
            setActiveBackendMessage('');
            if (response && response.status===200) {
                const data = response.data;
                if (data && data['data']) {
                    setActiveBackendMessage('');
                    const imageList = data['data'];
                    let imageInferenceDataList : ImageInferenceData[] = []
                    imageList.forEach((imageInferenceData: any) => {
                        const eachImageData: ImageInferenceData = {
                            name: imageInferenceData.name ? imageInferenceData.name : getFileNameFromUrl(imageInferenceData.url),
                            size: imageInferenceData.size > 0 ? imageInferenceData.size : 0,
                            status: imageInferenceData.status ? imageInferenceData.status : '',
                            uploadedDate: formatEpochTimestampDate(imageInferenceData.uploadedDate ? imageInferenceData.uploadedDate:''),
                            url: imageInferenceData.url
                        };
                        imageInferenceDataList.push(eachImageData);
                    })
                    const sortedImageInferenceData: ImageInferenceData[] = imageInferenceDataList.sort((a: ImageInferenceData, b: ImageInferenceData) => a.uploadedDate - b.uploadedDate); // sorted in descending order
                    setImageInferences(sortedImageInferenceData);
                    setIsLoading(false);
                } else {
                    setImageInferences([]);
                }
            }

        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response && (error.response.status === 401 || error.response.status === 500) || error.code === 'ERR_NETWORK') {
                    setActiveBackend(false);
                    if (error.response) setActiveBackendMessage(capitalize(error.response.statusText?.toLowerCase()));
                }
            }
            setIsLoading(false);
        }
    };

    const handleUploadImages = async (imageFiles: File[]) => {
        if (!imageFiles || imageFiles.length === 0) return;

        try {
            const formData = new FormData();
            formData.append("user", JSON.stringify({id: authUser?.uid}));
            imageFiles.forEach((file: File, fileIndex: number) => {
                formData.append("images", file);
            })

            const response = await FetchService.imageBrandsBatchInference(formData);

            if (response && response.status === 201) {
                // {"file_name": file.filename, "is_successful": True, "comment": "No issues."}
                notifySuccess("Image has been uploaded successfully.");
                await loadData(authUser?.uid);
            } else {
                console.error('Response is not a Blob:', response);
                notifyError("Image upload failed. Please try again.");
            }
        } catch (error) {
            console.error("Error uploading files:", error);
            notifyError("An unexpected error occurred during the image upload.");
        }
        handleToggleModal();
    }
    const handleDeleteImageInferencesModal = () => {
        toggleDeleteImageInferencesModal();
    };

    const handleDeleteAllImages = async (data: string) => {
        try {
            const response = await FetchService.deleteImageInferenceByUserId(authUser?.uid);
            if (response && response.status === 200) {
                notifySuccess("All image inferences have been deleted successfully.");
                await loadData(authUser?.uid);
            } else {
                console.error('Response is not a Blob:', response);
                notifyError("Images deletion failed. Please try again.");
            }
        } catch (error) {
            console.error("Error delete file:", error);
            notifyError("An unexpected error occurred during the images deletion.");
        }
        toggleDeleteImageInferencesModal();
    };

    const handleDeleteImage = async (imageInferenceData: ImageInferenceData) => {
        try {
            const requestBody = {video: {url: imageInferenceData.url}};
            // const response = await FetchService.deleteImageInferenceByUserId(authUser?.uid);
            const response = await FetchService.deleteVideo(requestBody);
            if (response && response.status === 200) {
                notifySuccess("Image inference has been deleted successfully.");
                await loadData(authUser?.uid);
            } else {
                console.error('Response is not a Blob:', response);
                notifyError("Image deletion failed. Please try again.");
            }
        } catch (error) {
            console.error("Error delete file:", error);
            notifyError("An unexpected error occurred during the image deletion.");
        }
        toggleDeleteInferenceModal();
    };

    const handleDeleteInferenceModal = (inferenceData: ImageInferenceData) => {
        toggleDeleteInferenceModal();
        setSelectedImageInferenceData(inferenceData);
    };

    const toggleDeleteImageInferencesModal = () => {
        setDeleteInferencesModal(!deleteInferencesModal);
        removeBodyCss();
    }

    const toggleDeleteInferenceModal = () => {
        setDeleteInferenceModal(!deleteInferenceModal);
        removeBodyCss();
    }

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    }

    const handleDownloadZipImageInferences = async () => {
        await loadData(authUser?.uid);
        const completedImageInferences: ImageInferenceData[] = imageInferences.filter(fileData => fileData.status === InferenceState.COMPLETED);
        await downloadAllInferenceImages(completedImageInferences);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title={{label: APP_CONFIG_DEFAULT.title,  path: APP_CONFIG_DEFAULT.path}}
                                breadcrumbItem={{label:APP_IMAGE_INFERENCE_PAGE.label, path:APP_IMAGE_INFERENCE_PAGE.path}}/>
                    <Row>
                        <Col lg={12} xl={12}>
                            <Card>
                                <CardBody>
                                    <ImageInferenceDataTableActionMenu
                                        activeBackend={activeBackend}
                                        modalOpen={isModalOpen}
                                        onToggleModal={handleToggleModal}
                                        onDownloadImageInferences={handleDownloadZipImageInferences}
                                        onDeleteImageInferences={handleDeleteImageInferencesModal}
                                        imageInferences={imageInferences}
                                        onHandleUploadImages={handleUploadImages}
                                    />
                                    <ImageDataTableLightBox activeBackend={activeBackend} imageInferences={imageInferences} onDeleteImageInference={handleDeleteInferenceModal}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                {deleteInferenceModal && selectedImageInferenceData &&
                    <DeleteModal isModalOpen={deleteInferenceModal} title="Delete Inference" bodyMessage={selectedImageInferenceData?.name!}
                                 deleteActionLabel="Delete"
                                 toggleModal={toggleDeleteInferenceModal}
                                 data={selectedImageInferenceData}
                                 handleDeleteAction={handleDeleteImage}
                    />
                }

                <DeleteModal isModalOpen={deleteInferencesModal} title="Delete All Inferences" bodyMessage="all inferences"
                             deleteActionLabel="Delete"
                             toggleModal={toggleDeleteImageInferencesModal}
                             data={1}
                             // handleDeleteAction={handleDeleteAllImage}
                             handleDeleteAction={handleDeleteAllImages}
                />
            </div>
        </React.Fragment>
    );
};

export default ImageInference;
