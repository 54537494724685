import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Container, Form, Label, Row} from "reactstrap";

import {ADMIN_USER_EDIT_SUB_PAGE, APP_ADMIN_PAGE, APP_CONFIG_DEFAULT} from "../../config/config";
import Breadcrumb from "../../common/Breadcrumb";
import {AuthUserData} from "../../constants/models/Models";
import {useLocation, useNavigate} from "react-router-dom";
import Select from "react-select";
import {RoleType} from "../../constants/enums/Auth";
import {RoleProps} from "../../constants/types";
import {capitalizeWords, getUserRoleType} from "../../utils/utils";
import FirebaseAuthService from "../../services/FirebaseAuthService";
import {getFirebaseAuthService} from "../../utils/firebase_utils";
import {notifyError, notifySuccess} from "../../services/NotificationService";
import {AxiosError} from "axios";
import AuthUserService from "../../services/AuthUserService";

const UserEdit = () => {
    document.title = ADMIN_USER_EDIT_SUB_PAGE.label + " | " + APP_CONFIG_DEFAULT.title;

    const navigate = useNavigate();
    const location = useLocation();
    const [roles, setRoles] = useState<RoleProps[] | null>(null);
    const { authUser } = location.state || {};
    const [userData, setUserData] = useState<AuthUserData | null>(authUser);
    const [allRoles, setAllRoles] = useState<RoleProps[] | null>(null);

    const ALL_ROLES: RoleProps[] = [
        {label: capitalizeWords(RoleType.USER), value: RoleType.USER},
        {label: capitalizeWords(RoleType.ADMIN_USER), value: RoleType.ADMIN_USER},
        {label: capitalizeWords(RoleType.SUPER_ADMIN_USER), value: RoleType.SUPER_ADMIN_USER}
    ];

    useEffect(() => {
        if (!authUser) {
            navigate(APP_ADMIN_PAGE.path);
        }
        const authUserRoles = AuthUserService.getLoggedAuthorizedUser()?.roles;
        if (authUserRoles?.includes(RoleType.SUPER_ADMIN_USER)) {
            setAllRoles([
                {label: capitalizeWords(RoleType.USER), value: RoleType.USER},
                {label: capitalizeWords(RoleType.ADMIN_USER), value: RoleType.ADMIN_USER},
                {label: capitalizeWords(RoleType.SUPER_ADMIN_USER), value: RoleType.SUPER_ADMIN_USER}
            ]);
        } else if (authUserRoles?.includes(RoleType.ADMIN_USER)) {
            setAllRoles([
                {label: capitalizeWords(RoleType.USER), value: RoleType.USER},
            ]);
        }
        const userRoles = userData?.roles;
        if (userRoles) {
            setRoles(userRoles.map(role => ({label: capitalizeWords(role), value: getUserRoleType(role)})))
        }
        setUserData(authUser);
    },[userData]);

    const handleSelectRoles = (roles: RoleProps[]) => {
        setRoles(roles)
    }

    const handleUpdateRoles = async () => {
        try {
            const firebaseAuthService: FirebaseAuthService | null = getFirebaseAuthService();
            if (roles && userData) {
                const selectedRoles: RoleType[] = roles.map(role => { return role.value});
                const result = await firebaseAuthService?.updateUserRoles(userData, selectedRoles);
                if (result) {
                    notifySuccess(result);
                } else {
                    notifyError(`Unable to update user roles. Please try again.`);
                }
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                notifyError(`Unable to update user roles due to ${error.message}. Please try again.`);
            } else {
                notifyError(`Unable to update user roles. Please try again.`);
            }
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumb title={{label: APP_CONFIG_DEFAULT.title, path: APP_CONFIG_DEFAULT.path}}
                                breadcrumbItem={{label:APP_ADMIN_PAGE.label, path: APP_ADMIN_PAGE.path}}
                                subBreadcrumbItem={{label:ADMIN_USER_EDIT_SUB_PAGE.label, path:ADMIN_USER_EDIT_SUB_PAGE.path}}/>

                    <Col lg={12} xl={12}>
                        <Card>
                            <CardBody>
                                <Form className="form-horizontal" id="user-edit">
                                    <div className="card border shadow-none mb-5">
                                        <CardBody>
                                            <Row>
                                                <div className="mb-3">
                                                    <Label className="text-muted mb-1">Email {" "}:</Label>
                                                    <h5 className="font-size-14 text-truncate">{userData?.email?userData?.email:""}</h5>
                                                </div>
                                            </Row>
                                            <Row>
                                                <Col lg={3}>
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="addressInfo.country.name">Role</Label>
                                                        <Select
                                                            value={roles}
                                                            options={allRoles}
                                                            onChange={handleSelectRoles}
                                                            placeholder="Role..."
                                                            isMulti
                                                            classNamePrefix="select"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={9}>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </div>
                                    <div className="text-end">
                                        <Button type="button" color="btn btn-primary w-sm" disabled={roles?.length === 0} onClick={handleUpdateRoles}>Update</Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UserEdit;