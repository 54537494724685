import React, { useEffect, useState } from "react";
import { APP_CONFIG_DEFAULT, APP_INFERENCE_PAGE } from "../../config/config";

//redux
import FetchService from "../../services/FetchService";
import withRouter from "../../common/withRouter";
import { capitalize, formatBytes, formatEpochTimestampDate, getFileNameFromUrl } from "../../utils/utils";
import { AxiosError, AxiosProgressEvent } from "axios";
import { AuthUserData } from "../../constants/models/Models";
import AuthUserService from "../../services/AuthUserService";
import { InferenceState } from "../../constants/enums/inference_enums";
import {
  FileUploadCount,
  ProcessedVideoStateData,
  VideoInferenceProject,
  UploadVideoFileData,
  VideoFileProjectData,
} from "../../constants/types";
import { ActiveTabType } from "../../constants/enums/layout";
import { notifyError, notifySuccess } from "../../services/NotificationService";
import {
  DROPZONE_MULTIPLE_VIDEO_FILES,
  INITIAL_VIDEO_FILE_UPLOAD_COUNT,
  INITIAL_VIDEO_FILE_PROJECT_DATA,
} from "../../constants/constants";
import { getUserXApiKey, headerContentType } from "../../utils/auth-token-header";
import {
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import Breadcrumbs from "../../common/Breadcrumb";
import VideoInferenceDataTableActionMenu from "./VideoInferenceDataTableActionMenu";
import classnames from "classnames";
import { InferenceDataTable } from "./InferenceDataTable";
import { DeleteModal } from "../Common/Modals/DeleteModal";
import { UserStatus } from "../../constants/enums/Auth";
import { downloadAllProcessedVideoData } from "../../utils/video_inference_utils";
import { set } from "lodash";
import { useLocation } from "react-router-dom";

const Inference = () => {
  document.title = APP_INFERENCE_PAGE.label + " | " + APP_CONFIG_DEFAULT.title;
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [activeBackendMessage, setActiveBackendMessage] = useState("");
  const [videoInferenceProjects, setVideoInferenceProjects] = useState<VideoInferenceProject[]>([]);
  const [videoInferences, setVideoInferences] = useState<Array<ProcessedVideoStateData>>([]);
  const [activeTab, setActiveTab] = useState<ActiveTabType>(ActiveTabType.ALL);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoProject, setVideoFileProject] = useState<VideoFileProjectData>(INITIAL_VIDEO_FILE_PROJECT_DATA);
  const [fileUploadCount, setFileUploadCount] = useState<FileUploadCount>(INITIAL_VIDEO_FILE_UPLOAD_COUNT);
  const [deleteInferenceVideoModal, setDeleteInferenceVideoModal] = useState<boolean>(false);
  const [deleteAllInferenceVideoModal, setDeleteAllInferenceVideoModal] = useState<boolean>(false);
  const [selectedProcessedInference, setSelectedProcessedInference] = useState<ProcessedVideoStateData | null>(null);
  const authUser: AuthUserData = AuthUserService.getLoggedAuthorizedUser();
  const [currentProject, setCurrentProject] = useState(null);
  const [activeBackend, setActiveBackend] = useState<boolean>(
    authUser.status?.toLowerCase() === UserStatus.ACTIVE.toLowerCase() &&
      (authUser?.accessXApiKey !== "" || authUser.accessXApiKey !== undefined)
  );
  const location = useLocation();
  
  useEffect(() => {
    let projectName = location?.state?.projectName;
    setCurrentProject(projectName)

  }, []);

  useEffect(() => {
    fetchData(authUser?.uid).then(); // Initial fetch

    const intervalId = setInterval(() => {
      const hasNotCompleted = videoInferences.some((item) => item.state !== InferenceState.COMPLETED);
      if (hasNotCompleted) {
        fetchData(authUser?.uid).then();
      }
    }, 15000); // Fetch data every 15000 milliseconds (15 seconds)

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [videoInferences.some((item) => item.state !== InferenceState.COMPLETED)]); // Depend on videoInferences to update when it changes

  const fetchData = async (userId: string) => {
    try {
      const response = await FetchService.getVideoInferenceByUserId(userId);
      setActiveBackend(true);
      if (response && response.status === 200) {
        const data = response.data;
        if (data instanceof Array) {
          setActiveBackendMessage("");
          const sortedData = data.sort((a, b) => a.time_created - b.time_created); // sorted in descending order
          const formattedData: ProcessedVideoStateData[] = sortedData.map((eachCellData) => {
            return {
              ...eachCellData,
              name: getFileNameFromUrl(eachCellData.urls.video),
              time_created: formatEpochTimestampDate(eachCellData.time_created!),
              size: formatBytes(eachCellData.size),
              state: capitalize(eachCellData.state),
            };
          });

          // const myData: VideoInferenceProject[] = [];
          // formattedData.map((data) => {
          //     if (myData[data.project]) {
          //
          //     } else {
          //         myData.push({
          //             project: data.project,
          //             [InferenceState.COMPLETED]: data.state.toLowerCase() === InferenceState.COMPLETED ? 1 : 0,
          //             [InferenceState.PROCESSING]: data.state.toLowerCase() === InferenceState.PROCESSING ? 1 : 0,
          //             [InferenceState.PENDING]: data.state.toLowerCase() === InferenceState.PENDING ? 1 : 0,
          //             [InferenceState.FAILED]: data.state.toLowerCase() === InferenceState.FAILED ? 1 : 0,
          //             time_created: data.time_created,
          //             total: 1
          //         });
          //     }
          // })
          if (currentProject) {
            setVideoInferences(formattedData.filter((d) => d.project === currentProject));
          } else {
            setVideoInferences(formattedData);
          }
          setIsLoading(false);
        } else {
          setVideoInferences([]);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          (error.response && (error.response.status === 401 || error.response.status === 500)) ||
          error.code === "ERR_NETWORK"
        ) {
          setActiveBackend(false);
          if (error.response) setActiveBackendMessage(capitalize(error.response.statusText?.toLowerCase()));
        }
      }
      setIsLoading(false);
      setVideoInferences([]);
    }
  };

  const handleDeleteInferenceVideo = async (processedVideoData: ProcessedVideoStateData) => {
    try {
      const requestBody = { video: { url: processedVideoData.urls.video } };
      const response = await FetchService.deleteVideo(requestBody);
      if (response && response.status === 200) {
        toggleDeleteInferenceVideoModal();
        notifySuccess("Video has been deleted successfully.");
        await fetchData(authUser?.uid);
      } else {
        toggleDeleteInferenceVideoModal();
        console.error("Response is not a Blob:", response);
        notifyError("Video deletion failed. Please try again.");
      }
    } catch (error) {
      toggleDeleteInferenceVideoModal();
      console.error("Error delete file:", error);
      console.error(error);
      notifyError("An unexpected error occurred during the video deletion.");
    }
  };

  const handleDeleteAllInferenceVideo = async (inferences: ProcessedVideoStateData[]) => {
    try {
      let isDeleted = false;
      for (let i = 0; i < inferences.length; i++) {
        const eachInference = inferences[i];
        const requestBody = { video: { url: eachInference.urls.video } };
        const response = await FetchService.deleteVideo(requestBody);
        if (response && response.status === 200) {
          isDeleted = true;
        }
      }
      toggleDeleteAllInferenceVideoModal();
      if (isDeleted) notifySuccess("All video inferences have been deleted successfully.");
      await fetchData(authUser?.uid);
    } catch (error) {
      toggleDeleteAllInferenceVideoModal();
      console.error("Error delete file:", error);
      console.error(error);
      notifyError("An unexpected error occurred during the video deletion.");
    }
  };

  const handleDeleteInferenceVideoModal = async (processedVideo: ProcessedVideoStateData) => {
    setSelectedProcessedInference(processedVideo);
    toggleDeleteInferenceVideoModal();
  };

  const toggleDeleteInferenceVideoModal = () => {
    setDeleteInferenceVideoModal(!deleteInferenceVideoModal);
    document.body.classList.add("no_padding");
  };

  const toggleDeleteAllInferenceVideoModal = () => {
    setDeleteAllInferenceVideoModal(!deleteAllInferenceVideoModal);
    document.body.classList.add("no_padding");
  };

  const handleToggleModal = () => setIsModalOpen(!isModalOpen);

  const handleDownloadAll = async () => {
    setIsDownloading(true);
    await fetchData(authUser?.uid);
    await downloadAllProcessedVideoData(videoInferences, authUser);
    setIsDownloading(false);
  };

  const handleDeleteAll = async () => {
    toggleDeleteAllInferenceVideoModal();
    await fetchData(authUser?.uid);
  };

  const updateData = (fileName: string) => {
    setVideoFileProject((prevState) => ({
      ...prevState,
      uploadFileData: {
        ...prevState.uploadFileData,
        status: InferenceState.SELECTED,
        uploadPercentage: 0,
      },
    }));
  };

  const updateVideoProjectPercentageStatus = (
    fileData: UploadVideoFileData,
    percentage: number,
    status: InferenceState
  ) => {
    setVideoFileProject((prevState) => ({
      ...prevState,
      uploadFileData: prevState.uploadFileData.map((currentFile: UploadVideoFileData) =>
        currentFile.file.name === fileData.file.name
          ? { ...currentFile, uploadPercentage: percentage, status: status }
          : currentFile
      ),
    }));

    setFileUploadCount((prevState) => ({
      ...prevState,
      status: fileData.fileNumber === fileUploadCount.totalCount ? status : InferenceState.UPLOADING,
      fileNumber: fileData.fileNumber,
    }));
  };

  const uploadVideo = async (projectName: string, fileData: UploadVideoFileData) => {
    const eachFormData = new FormData();
    eachFormData.append("user", JSON.stringify({ id: authUser?.uid }));
    eachFormData.append(fileData.file.name, fileData.file);
    if (projectName) {
      eachFormData.append("project", projectName);
    }

    const response = await FetchService.uploadVideo(eachFormData, {
      headers: {
        "Content-Type": headerContentType.FORM_DATA_TYPE,
        "X-API-KEY": getUserXApiKey(),
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total!);
        updateVideoProjectPercentageStatus(fileData, percentCompleted, InferenceState.UPLOADING);
      },
    });

    if (response && response.status === 200) {
      updateVideoProjectPercentageStatus(fileData, 100, InferenceState.UPLOADED);
      await fetchData(authUser?.uid);
    } else {
      console.error("Response is not a Blob:", response);
      notifyError(`${fileData.file.name} video upload failed. Please try again.`);
    }
  };

  const handleVideoUpload = async (videoProjectData: VideoFileProjectData) => {
    try {
      for (const fileData of videoProjectData.uploadFileData) {
        
        if (currentProject) await uploadVideo(currentProject, fileData);
        else await uploadVideo("", fileData);
      }
      handleToggleModal();
      if (fileUploadCount.totalCount === 1) {
        notifySuccess(`Video has been uploaded successfully.`);
      } else {
        notifySuccess(`All ${fileUploadCount.totalCount} videos have been uploaded successfully.`);
      }
      // notifySuccess("All Videos have been uploaded successfully.");
    } catch (error) {
      console.error("Error uploading files:", error);
      notifyError("An unexpected error occurred during the video upload.");
    }
  };

  const handleBulkVideoUpload = async (videoProjectData: VideoFileProjectData) => {
    if (!DROPZONE_MULTIPLE_VIDEO_FILES) {
      try {
        // Update Status
        const formData = new FormData();
        formData.append("user", JSON.stringify({ id: authUser?.uid }));
        if (!videoProjectData.project) {
          formData.append("project", videoProjectData.project);
        }

        videoProjectData.uploadFileData.forEach((file: File) => {
          formData.append(file.name, file);
        });

        const response = await FetchService.uploadVideo(formData, {
          headers: {
            "Content-Type": headerContentType.FORM_DATA_TYPE,
            "X-API-KEY": getUserXApiKey(),
          },
        });

        if (response && response.status === 200) {
          await fetchData(authUser?.uid);
          handleToggleModal();
          notifySuccess("Video has been uploaded successfully.");
        } else {
          console.error("Response is not a Blob:", response);
          notifyError("Video upload failed. Please try again.");
        }
      } catch (error) {
        console.error("Error uploading files:", error);
        notifyError("An unexpected error occurred during the video upload.");
      }
    }
  };

  const toggleActiveTab = (tab: ActiveTabType) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <React.Fragment>
      {isDownloading && (
        <Modal isOpen={true}>
          <ModalHeader>Compressing Data</ModalHeader>
          <ModalBody>
            <Container fluid>
              <Row className="mt-3">
                <Col></Col>
                <Col>
                  <Spinner color="primary" style={{ width: "5rem", height: "5rem" }}></Spinner>
                </Col>
                <Col></Col>
              </Row>
              <Row className="mt-3">
                <p>This process may take some time, depending on the number of videos uploaded.</p>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={{ label: APP_CONFIG_DEFAULT.title, path: APP_CONFIG_DEFAULT.path }}
            breadcrumbItem={{ label: APP_INFERENCE_PAGE.label, path: APP_INFERENCE_PAGE.path }}
          />
          <Row>
            <Col lg={12} xl={12}>
              <Card>
                <CardBody>
                  <VideoInferenceDataTableActionMenu
                    activeBackend={activeBackend}
                    modalOpen={isModalOpen}
                    onToggleModal={handleToggleModal}
                    videoInferences={videoInferences}
                    onDownloadAll={handleDownloadAll}
                    onDeleteAll={handleDeleteAll}
                    onHandleUploadVideo={handleVideoUpload}
                    videoProjectData={videoProject}
                    setVideoProject={setVideoFileProject}
                    fileUploadCount={fileUploadCount}
                    setFileUploadCount={setFileUploadCount}
                  />
                  <Row>
                    <Nav tabs className="nav-tabs-custom mb-4">
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === ActiveTabType.ALL })}
                          onClick={() => toggleActiveTab(ActiveTabType.ALL)}
                        >
                          {capitalize(ActiveTabType.ALL)}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === ActiveTabType.PENDING })}
                          onClick={() => toggleActiveTab(ActiveTabType.PENDING)}
                        >
                          {capitalize(ActiveTabType.PENDING)}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === ActiveTabType.PROCESSING })}
                          onClick={() => toggleActiveTab(ActiveTabType.PROCESSING)}
                        >
                          {capitalize(ActiveTabType.PROCESSING)}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === ActiveTabType.COMPLETED })}
                          onClick={() => toggleActiveTab(ActiveTabType.COMPLETED)}
                        >
                          {capitalize(ActiveTabType.COMPLETED)}
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={ActiveTabType.ALL}>
                        <Row>
                          <InferenceDataTable
                            isDataLoading={isLoading}
                            activeBackend={activeBackend}
                            inferences={videoInferences}
                            onDeleteInferenceVideo={handleDeleteInferenceVideoModal}
                          />
                        </Row>
                      </TabPane>
                    </TabContent>

                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={ActiveTabType.PENDING}>
                        <Row>
                          <InferenceDataTable
                            isDataLoading={isLoading}
                            activeBackend={activeBackend}
                            inferences={videoInferences.filter(
                              (item) => item.state.toLowerCase() === InferenceState.PENDING
                            )}
                            onDeleteInferenceVideo={handleDeleteInferenceVideoModal}
                          />
                        </Row>
                      </TabPane>
                    </TabContent>

                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={ActiveTabType.PROCESSING}>
                        <Row>
                          <InferenceDataTable
                            isDataLoading={isLoading}
                            activeBackend={activeBackend}
                            inferences={videoInferences.filter(
                              (item) => item.state.toLowerCase() === InferenceState.PROCESSING
                            )}
                            onDeleteInferenceVideo={handleDeleteInferenceVideoModal}
                          />
                        </Row>
                      </TabPane>
                    </TabContent>

                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={ActiveTabType.COMPLETED}>
                        <Row>
                          <InferenceDataTable
                            isDataLoading={isLoading}
                            activeBackend={activeBackend}
                            inferences={videoInferences.filter(
                              (item) => item.state.toLowerCase() === InferenceState.COMPLETED
                            )}
                            onDeleteInferenceVideo={handleDeleteInferenceVideoModal}
                          />
                        </Row>
                      </TabPane>
                    </TabContent>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <DeleteModal
        isModalOpen={deleteInferenceVideoModal}
        title="Delete Video Inference"
        bodyMessage="this"
        deleteActionLabel="Delete"
        toggleModal={toggleDeleteInferenceVideoModal}
        data={selectedProcessedInference!}
        handleDeleteAction={handleDeleteInferenceVideo}
      />

      <DeleteModal
        isModalOpen={deleteAllInferenceVideoModal}
        title="Delete all video inferences"
        bodyMessage="all inferences"
        deleteActionLabel="Delete"
        toggleModal={toggleDeleteAllInferenceVideoModal}
        data={videoInferences}
        handleDeleteAction={handleDeleteAllInferenceVideo}
      />
    </React.Fragment>
  );
};

export default withRouter(Inference);
