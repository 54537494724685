import React from 'react';
import {Col, Row} from "reactstrap";
import {AspectRatioType} from "../../../constants/enums/inference_enums";
import {filteredVideoCSVData} from "../../../utils/video_inference_utils";
import {CSVDataProps, ScannerParameters, VideoObjectDetectionJsonData} from "../../../constants/types";
import {AuthUserData} from "../../../constants/models/Models";

interface VideoInferenceProps {
    videoRef: any;
    aspectRatioType: AspectRatioType;
    isLoadingVideo: boolean;
    parameters: ScannerParameters;
    objectDetectionJsonData: VideoObjectDetectionJsonData | null;
    authUser: AuthUserData;
}

const VideoPlayer = ({videoRef, aspectRatioType, isLoadingVideo, objectDetectionJsonData, authUser, parameters}:VideoInferenceProps) => {
    const filteredCSVData: CSVDataProps[] = filteredVideoCSVData(objectDetectionJsonData!, authUser, parameters);

    // Prepare video chapters
    const videoChapters: Map<number, CSVDataProps> = new Map<number, CSVDataProps>();
    filteredCSVData.forEach((csvData) => {
        if (videoChapters.has(csvData.adNumber)) {
            const existCSVData = videoChapters.get(csvData.adNumber);
            if (existCSVData) {
                existCSVData.endTimeCode = csvData.endTimeCode;
                videoChapters.set(csvData.adNumber, existCSVData);
            }
        } else {
            if (videoChapters.size === 0) {
                csvData.startTimeCode = '00:00:00:00'
            }
            videoChapters.set(csvData.adNumber, csvData);
        }
    })

    const handleChapterClick = (time: number) => {
        if (videoRef.current && videoRef.current.readyState === 4) {
            videoRef.current.currentTime = time;
            videoRef.current.play();
        }
    };

    const parseTime = (timeString: string) => {
        const [hours, minutes, seconds] = timeString.split(':').map(parseFloat);
        return hours * 3600 + minutes * 60 + seconds;
    };

    return (
        <React.Fragment>
            <Row className="d-flex align-items-center justify-content-center">
                <Col
                    xl={(aspectRatioType == AspectRatioType.RATIO_9x19 || aspectRatioType == AspectRatioType.RATIO_9x16) ? 3 : 12}>
                    <div className={`ratio ratio-${aspectRatioType}`}>
                        { isLoadingVideo &&
                            <div className="loading-overlay">
                                <div className="spinner"></div>
                            </div>
                        }
                        <video controls ref={videoRef} muted></video>
                    </div>
                </Col>
            </Row>
            {/*<Row>
                <div className="chapters">
                    {Array.from(videoChapters.values()).map((chapter, index) => (
                        <div className="chapter-link" key={index} onClick={() => handleChapterClick(parseTime(chapter.startTimeCode))} >
                            Ad {chapter.adNumber} ({chapter.startTimeCode})
                        </div>
                    ))}
                </div>
            </Row>*/}
        </React.Fragment>
    );
}
export default VideoPlayer;