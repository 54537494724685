import {UrlConfig} from "../constants/types";

export const SERVER_BASE_URL_CONFIG = (): UrlConfig => {
    const apiBaseUrlConfig: UrlConfig = process.env.REACT_APP_API_BASE_URL ? {apiBaseUrl: process.env.REACT_APP_API_BASE_URL} : require('./url_config.json')[process.env.NODE_ENV!] ? require('./url_config.json')[process.env.NODE_ENV!] : require('./url_config.json').development;
    return apiBaseUrlConfig;
};

// End points

// Image inferences
export const IMAGE_BRANDS_ENDPOINT = 'brands'
// export const INFERENCE_ENDPOINT = 'brands/food/infer';
export const IMAGE_BRANDS_INFERENCE_ENDPOINT = IMAGE_BRANDS_ENDPOINT + '/infer';
export const IMAGE_BRANDS_BATCH_INFERENCE_ENDPOINT = IMAGE_BRANDS_ENDPOINT + '/infer/batch';
export const IMAGE_INFERENCE_PARAMS = {
    min_score:90,
    draw_bbox:1,
};


// API Key Endpoint
export const API_KEY_ENDPOINT = 'apikey'; // apikey for get keys
export const API_KEY_VALID_ENDPOINT = API_KEY_ENDPOINT + '/valid';

// Video
export const VIDEO_INFERENCE_ENDPOINT = 'video';
export const VIDEO_INFERENCE_STREAM_ENDPOINT = VIDEO_INFERENCE_ENDPOINT + '/stream';

// Download
export const INFERENCE_VIDEO_DOWNLOAD_ENDPOINT = 'download';

// test
export const IISRI_DEAKIN_NAME = 'IISRI';
export const IHT_DEAKIN_NAME = 'IHT';
export const DEAKIN_NAME = 'Deakin University';
export const IISRI_DEAKIN_URL = 'https://iisri.deakin.edu.au';
export const IHT_DEAKIN_URL = 'https://iht.deakin.edu.au/globe';
export const DEAKIN_URL = 'https://deakin.edu.au';
