import {apiError, loginSuccess} from "./reducer";

import {APP_CONFIG_DEFAULT, AUTH_LOGIN_PAGE} from "../../../config/config";
import {getFirebaseAuthService} from "../../../utils/firebase_utils";

export const loginUser: any = (user: any, history: any) => async (dispatch: any) => {
    try {
        let response;
        const firebaseAuthService = getFirebaseAuthService();
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            response = firebaseAuthService!.loginUser(user.email, user.password);
        }

        const data = await response;

        if (data) {
            dispatch(loginSuccess(data));
            history(APP_CONFIG_DEFAULT.path);
        }
    } catch (error) {
        dispatch(apiError(error));
    }
};

export const logoutUser: any = (history: any) => async (dispatch: any) => {
    try {
        const firebaseAuthService = getFirebaseAuthService();
        await firebaseAuthService!.logout()
        history(AUTH_LOGIN_PAGE.path);
    } catch (error) {
        dispatch(apiError(error));
    }
};