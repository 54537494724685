import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import {
    DROPZONE_IMAGE_FORMATS,
    DROPZONE_MAX_IMAGE_FILES,
    DROPZONE_MAX_VIDEO_FILES,
    DROPZONE_MAX_VIDEO_MB_SIZE,
    DROPZONE_MULTIPLE_IMAGE_FILES,
    DROPZONE_MULTIPLE_VIDEO_FILES,
    DROPZONE_VIDEO_FORMATS
} from "../../../constants/constants";
import Dropzone from "react-dropzone";
import {InferenceType} from "../../../constants/enums/all_enums";
import {InferenceState} from "../../../constants/enums/inference_enums";
import {capitalize} from "../../../utils/utils";

interface FileUploadModalProps {
    isOpen: boolean;
    fileUploadType: InferenceType;
    toggleModal: () => void;
    handleUpload: (files: File[]) => void;
    title: string;
}

const FileUploadModal = ({isOpen, fileUploadType, toggleModal, handleUpload, title}: FileUploadModalProps) => {
    const [selectedFiles, setSelectedFiles] = useState<any>([]);
    const maxFileSize = DROPZONE_MAX_VIDEO_MB_SIZE * 1024 * 1024; // MB in bytes
    const [inferenceStatus, setInferenceStatus] = useState<InferenceState>(InferenceState.SELECTED)

    useEffect(() => {
        setSelectedFiles([]);
        setInferenceStatus(InferenceState.SELECTED);
    },[isOpen])

    const handleAcceptedFiles = (acceptedFiles: File[]) => {
        setSelectedFiles(acceptedFiles);
    }

    const handleFileUpload = async () => {
        if (!selectedFiles || selectedFiles.length === 0) return;
        setInferenceStatus(InferenceState.UPLOADING);
        handleUpload(selectedFiles);
    }

    const toggleAndClear = () => {
        toggleModal();
        setSelectedFiles([]);
    }


    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggleAndClear} backdrop={"static"}>
                <ModalHeader toggle={toggleAndClear} tag="h5">{title}</ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Dropzone
                                maxFiles={fileUploadType === InferenceType.VIDEO ? DROPZONE_MAX_VIDEO_FILES : DROPZONE_MAX_IMAGE_FILES}
                                multiple={fileUploadType === InferenceType.VIDEO ? DROPZONE_MULTIPLE_VIDEO_FILES : DROPZONE_MULTIPLE_IMAGE_FILES}
                                accept={fileUploadType === InferenceType.VIDEO ? DROPZONE_VIDEO_FORMATS : DROPZONE_IMAGE_FORMATS}
                                onDrop={acceptedFiles => {
                                    handleAcceptedFiles(acceptedFiles);
                                }}>
                                {({getRootProps, getInputProps}) => (
                                    <div className="dropzone-modal">
                                        <div className="dz-message needsclick" {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <div className="mb-3">
                                                <i className="icon-display-4 text-muted fas fa-cloud-upload-alt"></i>
                                            </div>
                                            {selectedFiles.length > 0 && inferenceStatus == InferenceState.SELECTED &&
                                                <h5>You have {inferenceStatus}
                                                    <strong>{" "}{selectedFiles.length}</strong> {selectedFiles.length === 1 ? 'image' : 'images'}
                                                </h5>
                                            }
                                            {selectedFiles.length > 0 && inferenceStatus == InferenceState.UPLOADING &&
                                                <h5>
                                                    {capitalize(inferenceStatus)} <strong>{selectedFiles.length}</strong> {selectedFiles.length === 1 ? 'image' : 'images'}
                                                </h5>
                                            }

                                            {selectedFiles.length === 0 &&
                                                <h5>Drag & Drop your {fileUploadType === InferenceType.VIDEO ? "video" : "image"} file here or Click to browse</h5>
                                            }
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </Row>
                    </Form>
                </ModalBody>

                <ModalFooter>
                    <Row>
                        <Col lg={12} md={12} xs={12}>
                            <div className="text-end mt-2">
                                <div className="d-flex justify-content-end flex-wrap gap-2 mb-3">
                                    <Button color="btn btn-secondary-subtle" onClick={toggleAndClear} disabled={inferenceStatus === InferenceState.UPLOADING}>
                                        Cancel
                                    </Button>
                                    <Button color="btn btn-primary-subtle" onClick={handleFileUpload}
                                            disabled={selectedFiles.length === 0 || inferenceStatus === InferenceState.UPLOADING}
                                    >
                                        Upload
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

export default FileUploadModal;